import {Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {GoogleFontsService} from '../../../core/services/google/fonts/google-fonts.service';

import {WebsiteFontsDataModel} from '../../../core/models/google/fonts/website-fonts-data.model';
import {GoogleFontModel} from '../../../core/models/google/fonts/google-font.model';

import {PERMISSIONS} from '../../../core/services/service-permissions/constants';

@Component({
  selector: 'app-fonts-dropdown',
  templateUrl: './fonts-dropdown.component.html',
  styleUrls: ['./fonts-dropdown.component.scss'],
})
export class FontsDropdownComponent implements OnInit, OnDestroy {
  private _selectedFont: string = null;

  public get selectedFont(): string {
    return this._selectedFont;
  }

  @Input()
  public set selectedFont(value: string) {
    this._selectedFont = value;

    this.initIsFontValid();
  }

  @Output() changeHandler: EventEmitter<GoogleFontModel> = new EventEmitter<GoogleFontModel>();

  @ViewChild('scrollableWrapper') scrollableWrapper: ElementRef;

  public fontRemovedTooltipText: string = 'Font was removed. Please select a new font.';

  public data: WebsiteFontsDataModel = null;

  public isExpanded: boolean = false;
  public isFontValid: boolean = false;

  public isWebsiteFontsExists: boolean = false;
  public isUserFontsExists: boolean = false;
  public isDefaultFontsExists: boolean = false;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  private get isEnoughSpace(): boolean {
    return this.distanceFromBottom > 340;
  }

  private get distanceFromBottom(): number {
    return window.innerHeight - this.hostRef.nativeElement.getBoundingClientRect().top;
  }

  public get PERMISSIONS() {
    return PERMISSIONS;
  }

  private initIsFontValid(): void {
    if (!this.data) {
      this.isFontValid = false;

      return;
    }

    this.isFontValid = this.data.addedFonts.some(font => font.family === this.selectedFont)
                    || this.data.websiteFonts.some(font => font.family === this.selectedFont)
                    || this.data.defaultFonts.some(font => font.family === this.selectedFont);
  }

  constructor(private hostRef: ElementRef,
              private service: GoogleFontsService,
              private cdr: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.service.websiteDataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: WebsiteFontsDataModel) => {
      this.data = data;

      this.isWebsiteFontsExists = data && data.websiteFonts.length > 0;
      this.isUserFontsExists = data && data.addedFonts.length > 0;
      this.isDefaultFontsExists = data && data.defaultFonts.length > 0;

      this.initIsFontValid();
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onToggle(): void {
    this.isExpanded = !this.isExpanded;

    this.cdr.detectChanges();

    this.scrollToSelected();
  }

  private scrollToSelected(): void {
    if (!this.scrollableWrapper || !this.scrollableWrapper.nativeElement) return;

    if (!this.isEnoughSpace) this.hostRef.nativeElement.scrollIntoView();

    const selected = this.scrollableWrapper.nativeElement.querySelector('.font.selected');

    if (!selected) return;

    this.scrollableWrapper.nativeElement.scrollTop = selected.offsetTop - this.scrollableWrapper.nativeElement.clientHeight / 2 + selected.clientHeight / 2;
  }

  public onSelect(font: GoogleFontModel): void {
    this.changeHandler.emit(font);

    this.isExpanded = false;

    this.cdr.detectChanges();
  }

  public showModal(): void {
    this.service.openModal();
  }

  public onClose(): void {
    this.isExpanded = false;

    this.cdr.detectChanges();
  }
}
