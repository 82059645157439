import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Subject, Subscription, throwError} from 'rxjs';
import {catchError, finalize, takeUntil, tap} from 'rxjs/operators';

import {AdminService} from '../admin.service';
import {ModalsService} from '../../../../shared/services/modals/modals.service';
import {MessageModalService} from '../../../../services/message-modal.service';
import {ContentLoaderService} from '../../../../core/services/loaders/content/content-loader.service';
import {SocketsService} from '../../../../core/services/interaction/sockets/sockets.service';

import {ModalHeader} from '../../../../common/models/modal/header/header.model';
import {Button} from '../../../../common/models/button/button.model';
import {ISocketImportMessageDataModel} from '../../../../core/models/sockets/message/import/i-import-message-data.model';
import {ModalDataModel} from '../../../../core/models/modals/modal-data.model';

import {STATUSES} from './constants';

@Component({
  selector: 'app-admin-import-list',
  templateUrl: './admin-import-list.component.html',
  styleUrls: ['./admin-import-list.component.scss']
})
export class AdminImportListComponent implements OnInit, OnDestroy {
  public ERROR_MODAL_ID = 'admin-import-error-modal';

  public users: any[];

  public modalsStatus: { [key: string]: ModalDataModel } = {};

  public currPage = 1;
  public pageCount: number;

  private limit = 15;

  public customerFilterOptions = {};
  public importingUsers = {};

  public importErrorMessage: string = '';

  private successModalHeader = {
    text: 'Success!',
    className: 'success-header',
  };

  private successModalButtons = [
    {
      text: 'OK',
      className: 'neutral ok-button',
      onClick: () => this.messageModalService.close(),
    },
  ];

  private key = 'AdminImportListComponent';

  private socketMessagesHandlers: { [key: string]: (data: ISocketImportMessageDataModel) => void } = {
    [STATUSES.DONE]: this.onDone.bind(this),
    [STATUSES.FAILED]: this.onFailed.bind(this),
  };

  private errorModalButtons: Button[] = [
    {
      text: 'OK',
      className: 'neutral ok-button',
      onClick: this.onModalClose.bind(this),
    },
  ];

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get isImportingInProgress() {
    return Object.keys(this.importingUsers).length;
  }

  constructor(private httpClient: HttpClient,
              private adminService: AdminService,
              private cdr: ChangeDetectorRef,
              private modalsService: ModalsService,
              private loaderService: ContentLoaderService,
              private socketsService: SocketsService,
              private messageModalService: MessageModalService) {
    this.fetchLegacyAccount();
  }

  public ngOnInit(): void {
    this.modalsService.statusSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(modalsStatus => {
      this.modalsStatus = modalsStatus;

      this.cdr.detectChanges();
    });

    this.socketsService.importDataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: ISocketImportMessageDataModel) => {
      if (!data) return;

      if (!this.socketMessagesHandlers[data.status]) return console.dir(data);

      this.socketMessagesHandlers[data.status](data);
    });
  }

  public handleFilterSearch(options) {
    this.customerFilterOptions = options;
    this.currPage = 1;
    return this.fetchLegacyAccount();
  }

  public handlePageChange(newPage) {
    this.currPage = newPage;

    return this.fetchLegacyAccount();
  }

  private fetchLegacyAccount(): Subscription {
    this.loaderService.show(this.key);

    return this.adminService.fetchLegacyAccounts({
      options: this.customerFilterOptions,
      limit: this.limit,
      offset: this.limit * (this.currPage - 1),
    }).pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.hide(this.key);
      })
    ).subscribe((res: { users, pagination: { total } }) => {
      this.users = res.users;
      this.pageCount = res.pagination.total;
    });
  }

  public import(user) {
    this.importingUsers[user.Id] = true;

    this.handleLoader();

    this.adminService.importUser(user.Id, true).subscribe(() => {});
  }

  public onSendInviteClick(user): void {
    this.loaderService.show(this.key);

    const onSuccess = () => {
      const header: ModalHeader = {
        text: 'Successfully',
        className: 'success-header',
      };

      this.loaderService.hide(this.key);

      return this.messageModalService.addMessage('Message was sent.', header, this.errorModalButtons);
    };

    this.httpClient.post(`api/mails/invite-link`, { userId: user.Id }).pipe(
      catchError(e => {
        console.error(e);
  
        this.loaderService.hide(this.key);

        return throwError(() => e);
      }),
    ).subscribe(onSuccess);
  }

  private onDone(data: ISocketImportMessageDataModel): void {
    const message: string = `Reimport of userId '${data.userId}' completed successfully.`;

    this.messageModalService.addMessage(message, this.successModalHeader, this.successModalButtons);

    delete this.importingUsers[data.userId];

    this.handleLoader();
  }

  private onFailed(data: ISocketImportMessageDataModel): void {
    const err: string = typeof data.error === 'string' ? data.error : JSON.stringify(data.error);

    this.importErrorMessage = `An error occurred during import: '${err}'`;

    this.modalsService.open(this.ERROR_MODAL_ID);

    delete this.importingUsers[data.userId];

    this.handleLoader();
  }

  private onModalClose(): void {
    this.messageModalService.close();
  }

  private handleLoader(): void {
    return this.isImportingInProgress ? this.loaderService.show(this.key) : this.loaderService.hide(this.key);
  }

  public clearImportErrorMessage(): void {
    this.importErrorMessage = '';
  }

  public ngOnDestroy(): void {
    this.socketsService.importDataSubject.next(null);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
