import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {Subject, Subscription, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

import {UpgradeSubscriptionService} from '../../../../../core/services/payment/subscriptions/upgrade/upgrade-subscription.service';
import {PaymentSubscriptionsService} from '../../../../../core/services/payment/subscriptions/payment-subscriptions.service';
import {AuthService} from '../../../../../auth/auth.service';
import {UpgradeSubscriptionModalService} from '../../../../services/modals/upgrade-subscription/upgrade-subscription-modal.service';
import {CartService} from '../../../../../core/services/cart/cart.service';
import {PaymentService} from '../../../../../core/services/payment/payment.service';
import {AddOnsHttpService} from '../../../../../core/services/interaction/http/add-ons/add-ons-http.service';
import {ContentLoaderService} from '../../../../../core/services/loaders/content/content-loader.service';
import {ModalsService} from '../../../../services/modals/modals.service';

import {CardModel} from '../../../../../core/models/payment/card/card.model';
import {StripeCouponModel} from '../../../../../core/models/stripe/coupon/stripe-coupon.model';
import {ICartItem} from '../../../../../core/models/cart/item/cart-item.model';
import {PaymentModel} from '../../../../../core/models/payment/payment.model';
import {SubscriptionModel} from '../../../../../core/models/payment/subscriptions/subscription.model';

import {AppAnimations} from '../../../../../app-animations';

import {PRODUCT_TYPES} from '../../../../../core/services/cart/constants';
import {STATUSES_KEYS} from '../../../../../core/models/payment/subscriptions/constants';

@Component({
  selector: 'app-upgrade-subscription-modal',
  templateUrl: './upgrade-subscription-modal.component.html',
  styleUrls: ['./upgrade-subscription-modal.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class UpgradeSubscriptionModalComponent implements OnInit, OnDestroy {
  public cart: ICartItem[];

  public selectedCard: CardModel;

  public isCardValid: boolean = true;
  public isOrderValid: boolean = true;
  public isLoading: boolean = false;

  private paymentData: PaymentModel;
  private lastAddedCardId: string;

  private currentSubscription: SubscriptionModel;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get id(): string {
    return this.modalService.id;
  }

  private get isAddOnsOnlyPurchase(): boolean {
    if (!this.cart) return false;

    return this.cart.every((item: ICartItem) => item.type === PRODUCT_TYPES.ADD_ON);
  }

  constructor(private service: UpgradeSubscriptionService,
              private modalService: UpgradeSubscriptionModalService,
              private cartService: CartService,
              private loaderService: ContentLoaderService,
              private paymentService: PaymentService,
              private paymentSubscriptionsService: PaymentSubscriptionsService,
              private addOnsHttpService: AddOnsHttpService,
              private modalsService: ModalsService,
              private authService: AuthService) {
  }

  public ngOnInit() {
    this.paymentSubscriptionsService.initCurrent();
    
    this.paymentService.dataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((paymentData: PaymentModel) => {
      this.paymentData = paymentData;

      this.initCard();
    });

    this.paymentService.lastAddedCardIdSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((cardId: string) => {
      this.lastAddedCardId = cardId;

      this.initCard();
    });

    this.paymentSubscriptionsService.currentSubscriptionExpandedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: { isFetched: boolean, data: SubscriptionModel }) => {
      this.currentSubscription = res ? res.data : null;
    });

    this.cartService.cartSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((cart: ICartItem[]) => {
      this.cart = cart;
    });

    this.onCouponSubmit(null);
  }

  private initCard(): void {
    if (!this.paymentData) return;

    this.onCardSelect(this.paymentData.cards.find((card: CardModel) => {
      return this.lastAddedCardId ? card.id === this.lastAddedCardId : card.isDefault;
    }));
  }

  public ngOnDestroy(): void {
    this.paymentService.lastAddedCardIdSubject.next(null);

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public onCardSelect(card: CardModel): void {
    this.service.card = card;
    this.selectedCard = card;
  }

  public onCouponSubmit(coupon: StripeCouponModel): void {
    this.service.coupon = coupon;
    this.service.couponCode = coupon ? coupon.id : null;

    this.paymentSubscriptionsService.initProration(this.service.couponCode);
  }

  public onConfirm() {
    this.validate();

    if (!this.isCardValid || !this.isOrderValid) return;

    const planData: { cardId: string, coupon: string } = {
      cardId: this.service.card.id,
      coupon: this.service.couponCode,
    };

    this.isLoading = true;

    if (this.isAddOnsOnlyPurchase) {
      return this.onAddOnsPurchase(planData);
    }

    if (this.currentSubscription && this.currentSubscription.status !== STATUSES_KEYS.CANCELLED) {
      return this.onSubscriptionSwitch(planData);
    }

    return this.onSubscriptionPurchase(planData);
  }

  private onAddOnsPurchase(planData: { cardId: string, coupon: string }): Subscription {
    const key = 'UpgradeSubscriptionModalComponent_addOnsOnlyPurchase';

    const done = () => {
      this.addOnsHttpService.fetch();

      this.paymentService.init();

      this.afterPayment();
    };

    return this.addOnsHttpService.purchase(planData).pipe(
      catchError(e => {
        this.paymentService.init();
  
        this.authService.updateCurrentUser(false);
  
        this.handlePaymentError(e, key);

        return throwError(() => e);
      })
    ).subscribe(done);
  }

  private onSubscriptionSwitch(planData: { cardId: string, coupon: string }): Subscription {
    const key = 'UpgradeSubscriptionModalComponent_switchSubscription';

    this.loaderService.show(key);

    const done = () => {
      this.loaderService.hide(key);

      this.paymentService.init();

      this.paymentSubscriptionsService.initCurrent();

      this.afterPayment();
    };

    return this.paymentSubscriptionsService.switchSubscription(planData).pipe(
      catchError(e => {
        this.paymentService.init();
  
        this.paymentSubscriptionsService.initCurrent();
  
        this.authService.updateCurrentUser(false);
  
        this.handlePaymentError(e, key);

        return throwError(() => e);
      })
    ).subscribe(done);
  }

  private onSubscriptionPurchase(planData: { cardId: string, coupon: string }): Subscription {
    const key = 'UpgradeSubscriptionModalComponent_subscribe';

    this.loaderService.show(key);

    const done = () => {
      this.loaderService.hide(key);

      this.paymentService.init();

      this.paymentSubscriptionsService.initCurrent();

      this.afterPayment();
    };

    return this.paymentSubscriptionsService.subscribe(planData).pipe(
      catchError(e => {
        this.paymentService.init();
  
        this.paymentSubscriptionsService.initCurrent();
  
        this.authService.updateCurrentUser(false);
  
        this.handlePaymentError(e, key);

        return throwError(() => e);
      })
    ).subscribe(done);
  }

  private handlePaymentError(err: HttpErrorResponse, key: string): void {
    this.paymentSubscriptionsService.handleError(err, key);

    this.cartService.fetch();

    this.isLoading = false;

    this.modalService.close();

    if (err.error.key === 'NO_CREDIT_CARD' && (window.location.href.includes('settings/purchase-add-ons') || window.location.href.includes('settings/upgrade-subscription'))) return;

    this.handleError(err);
  }

  private handleError(err: HttpErrorResponse): void {
    console.error(err);

    this.modalsService.open(err.error.key, err.error);
  }

  private validate(): void {
    this.isCardValid = !!this.service.card && !!this.service.card.id;
    this.isOrderValid = !!this.cart && this.cart.length > 0;
  }

  private afterPayment(): Subscription {
    this.paymentSubscriptionsService.clear();

    this.paymentSubscriptionsService.toPurchase();

    this.cartService.fetch();

    this.isLoading = false;

    this.modalService.close();

    this.modalsService.open(this.paymentSubscriptionsService.successfullySubscribedModalId);

    return this.authService.updateCurrentUser(false);
  }

  public close(): void {
    this.modalService.close();
  }
}
