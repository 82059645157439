import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';

import {Observable, Subject, Subscription, forkJoin, throwError} from 'rxjs';
import {take, takeUntil, filter, catchError, tap, finalize, map, switchMap, mergeMap} from 'rxjs/operators';

import * as moment from 'moment';

import {AdminService} from '../../admin.service';
import {NavigationService} from '../../../../../services/navigation.service';
import {AuthService} from '../../../../../auth/auth.service';

import {ContentLoaderService} from '../../../../../core/services/loaders/content/content-loader.service';
import {PaymentCouponsService} from '../../../../../core/services/payment/coupons/payment-coupons.service';
import {AccountsCouponsHttpService} from '../../../../../core/services/interaction/http/accounts/coupons/accounts-coupons-http.service';
import {CustomSubscriptionsHttpService} from '../../../../../core/services/interaction/http/admin/custom-subscriptions/custom-subscriptions-http.service';
import {FailedImagesHandlingModalService} from '../../../../../shared/services/modals/failed-images-handling/failed-images-handling-modal.service';
import {RemoveSupportRowModalService} from '../../../../../shared/services/modals/remove-support-row/remove-support-row-modal.service';
import {WebsitesService} from '../../../../../core/services/websites/websites.service';
import {RoutingService} from '../../../../../core/services/routing/routing.service';
import {AdminSupportMetaService} from '../../../../../core/services/admin/support-meta/admin-support-meta.service';
import {UtilsService} from '../../../../../core/services/utils/utils.service';
import {WebsiteSubscriptionSelectModalService} from '../../../../../shared/services/modals/website-subscription-select/website-subscription-select-modal.service';
import { MessageModalService } from '../../../../../services/message-modal.service';

import {SubscriptionModel} from '../../../../../core/models/payment/subscriptions/subscription.model';
import {CustomSubscriptionModel} from '../../../../../core/models/custom-subscription/custom-subscription.model';
import {StripeCouponModel} from '../../../../../core/models/stripe/coupon/stripe-coupon.model';
import {AccountCouponModel} from '../../../../../core/models/accounts/coupons/account-coupon.model';
import {WebsiteModel} from '../../../../../core/models/websites/website.model';
import {AccountDto} from '../../../../../core/models/accounts/account.dto';
import {SupportMetaItemModel} from '../../../../../core/models/accounts/support-meta/support-meta-item.model';
import {SelectOption} from '../../../../../core/models/select/option/option.model';
import {AccountSummaryModel} from '../../../../../core/models/accounts/summary/account-summary.model';
import {SubscriptionPauseCollectionModel} from '../../../../../core/models/payment/subscriptions/subscription-pause-collection.model';
import {SubscriptionPauseCollectionDto} from '../../../../../core/models/payment/subscriptions/subscription-pause-collection.dto';
import {SubscriptionDto} from '../../../../../core/models/payment/subscriptions/subscription.dto';

import {DEFAULT_COUPON_ID, TIME_FORMATTERS, SUPPORT_METHODS, SUPPORT_STATUSES_KEYS, SUPPORT_SORT_KEYS, PUBLISH_STATUS_TEXT} from './constants';
import {COUPON_TYPES} from '../../../../../core/models/stripe/coupon/constants';

@Component({
  selector: 'app-admin-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss']
})
export class AdminCustomerInfoComponent implements OnInit, OnDestroy {
  public isLoaded: boolean;

  public tabs = {
    PROFILE: 'PROFILE',
    WEBSITES: 'WEBSITES',
    DOMAINS: 'DOMAINS',
    SUBSCRIPTIONS: 'SUBSCRIPTIONS',
    COUPONS: 'COUPONS',
    SUPPORT: 'SUPPORT',
  };

  customerId: number;
  public customer: AccountDto;

  websiteIds = [];
  domains = [];
  websites = [];

  activeTab = this.tabs.PROFILE;

  addDomainFormActive = false;
  newDomain = null;
  editDomainFormActive = false;
  editingDomain = null;

  errorMessage: string;

  public couponError: string = '';
  public supportError: string = '';

  public subscriptions: SubscriptionModel[] = [];

  public trialSubscription: SubscriptionModel;

  public trialEndFull: string = '';
  public trialEnd: string = moment().format(TIME_FORMATTERS.DATE);
  public minTrial: string = moment().format(TIME_FORMATTERS.DATE);
  public maxTrial: string = moment().add(2, 'years').format(TIME_FORMATTERS.DATE);

  public trialError: string = '';

  public customSubscriptions: CustomSubscriptionModel[] = [];
  public newCustomSubscription: CustomSubscriptionModel = new CustomSubscriptionModel();

  public accountSummary: AccountSummaryModel;

  public planSupportTimeMin: number;
  public addOnSupportTimeMin: number;

  public newSupportItem: SupportMetaItemModel = new SupportMetaItemModel(
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    SUPPORT_STATUSES_KEYS.VALID,
    null,
    moment().format(TIME_FORMATTERS.DATE),
  );

  public coupons: StripeCouponModel[] = [];

  public selectedCouponId: string = DEFAULT_COUPON_ID;

  public userCoupons: AccountCouponModel[] = [];
  public supportItems: SupportMetaItemModel[] = [];

  public supportSortBy: string = SUPPORT_SORT_KEYS.SUPPORT_DATE;
  public isSupportSortUp: boolean = false;

  public couponToDelete: AccountCouponModel;

  public isPurchaseTabsToggling: boolean = false;
  public isCouponRemoving: boolean = false;

  public supportSetupSaveStatus: string = null;
  public setSupportSetupSaveStatusTimeoutId: number;

  public customSubscriptionError: string = null;

  public isPauseSubscriptionInputFocused: boolean = false;

  private key = 'AdminCustomerInfoComponent';

  private loginErrorModalHeader = {
    text: 'Error on login',
    className: 'error-header',
  };

  private loginErrorModalButtons = [
    {
      text: 'OK',
      className: 'neutral ok-button',
      onClick: () => this.messageModalService.close(),
    },
  ];

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get couponTypes(): { GENERAL: string, UNIQUE: string, DROPDOWN: string } {
    return COUPON_TYPES;
  }

  public get supportSortKeys() {
    return SUPPORT_SORT_KEYS;
  }

  public get supportMethods(): SelectOption[] {
    return SUPPORT_METHODS;
  }

  public get publishStatusTexts(): { [key: string]: string } {
    return PUBLISH_STATUS_TEXT;
  }

  constructor(
    private adminService: AdminService,
    private adminSupportMetaService: AdminSupportMetaService,
    private accountsCouponsHttpService: AccountsCouponsHttpService,
    private customSubscriptionsHttpService: CustomSubscriptionsHttpService,
    private paymentCouponsService: PaymentCouponsService,
    private failedImagesHandlingModalService: FailedImagesHandlingModalService,
    private removeSupportRowModalService: RemoveSupportRowModalService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private websitesService: WebsitesService,
    private loaderService: ContentLoaderService,
    private routingService: RoutingService,
    private navigationService: NavigationService,
    private websiteSubscriptionSelectModalService: WebsiteSubscriptionSelectModalService,
    private messageModalService: MessageModalService,
    private utilsService: UtilsService
  ) {
    this.updateSupportItem = this.utilsService.debounce(this.updateSupportItem.bind(this), 250);

    this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      this.customerId = Number.parseInt(params['customerId']);

      this.loadCustomer(this.customerId);
    });
  }

  public ngOnInit(): void {
    this.authService.accountSummarySubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((accountSummary: AccountSummaryModel) => {
      this.accountSummary = accountSummary;

      this.planSupportTimeMin = accountSummary && accountSummary.planSupportTimeMin ? accountSummary.planSupportTimeMin : 0;
      this.addOnSupportTimeMin = accountSummary && accountSummary.addOnSupportTimeMin ? accountSummary.addOnSupportTimeMin : 0;
    });

    this.paymentCouponsService.couponsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((coupons: { list: StripeCouponModel[], hasMore: boolean }) => {
      this.coupons = coupons ? coupons.list : [];
    });

    this.adminSupportMetaService.customSubscriptionsSubject.pipe(
      takeUntil(this.ngUnsubscribe),
      filter((items: SupportMetaItemModel[]) => !!items)
    ).subscribe((items: SupportMetaItemModel[]) => {
      this.supportItems = items;
    });

    this.customSubscriptionsHttpService.customSubscriptionsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscriptions: CustomSubscriptionModel[]) => {
      this.customSubscriptions = subscriptions;
    });

    this.accountsCouponsHttpService.userCoupons.pipe(takeUntil(this.ngUnsubscribe)).subscribe((coupons: AccountCouponModel[]) => {
      this.userCoupons = coupons;
    });

    this.accountsCouponsHttpService.errorMessage.pipe(takeUntil(this.ngUnsubscribe)).subscribe((error: string) => {
      this.couponError = error;
    });

    this.websiteSubscriptionSelectModalService.onUpdate.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.prepareTab();
    });
  }

  selectTab(tab) {
    this.activeTab = tab;
    this.subscriptions = null;

    this.prepareTab();
  }

  prepareTab() {
    switch (this.activeTab) {
      case this.tabs.PROFILE: {
        break;
      }
      case this.tabs.WEBSITES: {
        this.prepareWebsitesTab();
        break;
      }
      case this.tabs.DOMAINS: {
        this.prepareDomainsTab().subscribe(() => {});
        break;
      }
      case this.tabs.SUBSCRIPTIONS: {
        this.fetchUserSubscriptions();
        this.customSubscriptionsHttpService.getAll();
        break;
      }
      case this.tabs.COUPONS: {
        if (!this.customer) return;

        this.accountsCouponsHttpService.getUserCoupons(this.customer.Id);
        break;
      }
    }
  }

  private prepareWebsitesTab(): Subscription {
    this.loaderService.show(this.key);

    return this.adminService.fetchWebsites(this.customer.Id).pipe(
      catchError(e => {
        this.websites = [];
  
        this.loaderService.hide(this.key);
        
        console.log(e);

        return throwError(() => e);
      }),
    ).subscribe((websites: WebsiteModel[]) => {
      this.websites = websites;

      this.loaderService.hide(this.key);
    });
  }

  public openWebsiteSubscriptionSelectModal({ website }: { website: WebsiteModel }): void {
    this.websiteSubscriptionSelectModalService.open({
      userId: this.customer.Id,
      website,
    });
  }

  prepareDomainsTab(): Observable<any> {
    this.loaderService.show(this.key);

    return this.adminService.fetchDomains(this.customer.Id).pipe(
      catchError(e => {
        this.websiteIds = [];
        this.domains = [];

        this.loaderService.hide(this.key);

        return throwError(() => e);
      }),
      tap((result: { websiteIds, websiteDomains }) => {
        this.websiteIds = result.websiteIds;
        this.domains = result.websiteDomains;
        this.loaderService.hide(this.key);
      })
    );
  }

  loadCustomer(customerId): Subscription {
    this.isLoaded = false;
    this.loaderService.show(this.key);

    this.authService.fetchAccountSummary(customerId);

    return this.adminService.loadCustomer(customerId).pipe(
      catchError(e => {
        console.error(e);
  
        this.customer = null;

        return throwError(() => e);
      }),
      finalize(() => {
        this.isLoaded = true;
        this.loaderService.hide(this.key);
      })
    ).subscribe(customer => {
      this.customer = customer;

      this.fetchSupportItems();
    });
  }

  openAddDomainForm() {
    this.clearErrorMessage();
    this.closeAllForms();

    this.newDomain = { DomainName: '', WebsiteID: this.websiteIds[0] };
    this.addDomainFormActive = true;
  }

  closeAddDomainForm() {
    this.newDomain = null;
    this.addDomainFormActive = false;
  }

  openEditDomainForm(websiteDomain) {
    this.clearErrorMessage();
    this.closeAllForms();

    this.editingDomain = Object.assign({}, websiteDomain, {DomainName: websiteDomain.domain.DomainName});

    this.editDomainFormActive = true;
  }

  closeEditDomainForm() {
    this.editingDomain = null;
    this.editDomainFormActive = false;
  }

  isNewDomainValid() {
    return this.newDomain.WebsiteID && this.newDomain.DomainName;
  }

  isEditingDomainValid() {
    return this.editingDomain && this.editingDomain.WebsiteID && this.editingDomain.DomainName;
  }

  clearErrorMessage() {
    this.errorMessage = null;
  }

  setErrorMessage = (msg) => {
    this.errorMessage = msg;
    this.loaderService.hide(this.key);
  };

  addDomain() {
    if (!this.isNewDomainValid()) {
      return;
    }

    this.loaderService.show(this.key);

    return this.adminService.addDomain(this.newDomain).pipe(
      mergeMap(domain => {
        return this.prepareDomainsTab().pipe(
          map(domains => {
            return [
              domain,
              domains,
            ];
          })
        )
      }),
      catchError(e => {
        this.setErrorMessage(e);

        return throwError(() => e);
      }),
      tap(() => {
        this.clearErrorMessage();
      }),
      finalize(() => {
        this.loaderService.hide(this.key);
      })
    ).subscribe(([domain, domains]: [any, any]) => {});
  }

  changePrimary(domain) {
    if (domain.PrimaryDomain) {
      return;
    }

    this.loaderService.show(this.key);

    return this.adminService.changePrimary(domain.WebsiteID, domain.DomainID).pipe(
      mergeMap(res1 => {
        return this.prepareDomainsTab().pipe(
          map(res2 => {
            return [
              res1,
              res2,
            ];
          })
        )
      }),
      catchError(e => {
        this.setErrorMessage(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.hide(this.key);
      })
    ).subscribe(() => {});
  }

  removeDomain(removedDomain) {
    this.loaderService.show(this.key);

    return this.adminService.removeDomain(removedDomain.WebsiteID, removedDomain.DomainID).pipe(
      mergeMap(res1 => {
        return this.prepareDomainsTab().pipe(
          map(res2 => {
            return [
              res1,
              res2,
            ];
          })
        )
      }),
      catchError(e => {
        this.setErrorMessage(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.hide(this.key);
      })
    ).subscribe(() => {});
  }

  updateDomain() {
    if (!this.isEditingDomainValid()) return;

    this.loaderService.show(this.key);

    return this.adminService.updateDomain({ DomainID: this.editingDomain.DomainID, DomainName: this.editingDomain.DomainName }).pipe(
      switchMap(() => {
        return this.prepareDomainsTab();
      }),
      catchError(e => {
        this.setErrorMessage(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.hide(this.key);
      })
    ).subscribe(() => {
      this.closeEditDomainForm();
      this.clearErrorMessage();
    });
  }

  public submitSubscriptionPause(subscription: SubscriptionModel): void {
    if (!subscription.pauseCollection.resumesAt) {
      this.adminService.setSubscriptionForFree(this.customerId, subscription.id, { resumesAt: -1 }).subscribe(() => {
        this.fetchUserSubscriptions();
      });

      return;
    }

    const pauseCollection: SubscriptionPauseCollectionDto = SubscriptionPauseCollectionModel.toRequest(subscription.pauseCollection);

    this.adminService.setSubscriptionForFree(this.customerId, subscription.id, { resumesAt: pauseCollection.resumes_at }).subscribe(() => {
      this.fetchUserSubscriptions();
    });
  }

  public unpauseSubscription(subscription: SubscriptionModel): void {
    this.adminService.setSubscriptionForFree(this.customerId, subscription.id, { resumesAt: null }).subscribe(() => {
      this.fetchUserSubscriptions();
    });
  }

  public deleteWebsiteFromAws(websiteId: number) {
    this.loaderService.show(this.key);

    return this.adminService.deleteWebsiteFromAws(websiteId).pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.hide(this.key);
      }),
    ).subscribe(() => {
      return this.enablePublishButton(websiteId);
    });
  }

  public enablePublishButton(websiteId: number) {
    this.loaderService.show(this.key);

    return this.adminService.enablePublishButton(websiteId).pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.hide(this.key);
      }),
    ).subscribe(() => {
      return this.prepareWebsitesTab();
    });
  }

  closeAllForms() {
    this.closeEditDomainForm();
    this.closeAddDomainForm();
  }

  loginAsUser(): Subscription {
    return this.adminService.loginAsUser(this.customerId).pipe(
      catchError(e => {
        console.error(e);

        this.messageModalService.addMessage(e.error.message, this.loginErrorModalHeader, this.loginErrorModalButtons);

        return throwError(() => e);
      })
    ).subscribe((user: AccountDto) => {
      this.authService.setUser(user);
  
      const unsubscribe: Subject<boolean> = new Subject<boolean>();
  
      this.websitesService.activeWebsiteSubject.pipe(takeUntil(unsubscribe)).subscribe((website: WebsiteModel) => {
        if (!website || website.id !== user.ActiveWebsiteID) return;
  
        unsubscribe.next(true);
        unsubscribe.complete();
  
        return website.isSetupCompleted ? this.navigationService.toHomePage() : this.routingService.toSetup();
      });
    });
  }

  private fetchUserSubscriptions() {
    return forkJoin([
      this.fetchActiveUserSubscriptions(),
      this.fetchTrialUserSubscription(),
    ]).subscribe(([subscriptions, trialSubscription]: [SubscriptionModel[], SubscriptionModel]) => {
      this.subscriptions = subscriptions;
      this.trialSubscription = trialSubscription;

      if (!this.trialSubscription) {
        return;
      }

      const time = moment(this.trialSubscription.trialEndAtTimestamp * 1000).utc();

      this.trialEndFull = time.format(TIME_FORMATTERS.FULL);
      this.trialEnd = time.format(TIME_FORMATTERS.DATE);
    });
  }

  private fetchActiveUserSubscriptions(): Observable<SubscriptionModel[]> {
    const key = 'AdminCustomerInfoComponent_fetchActiveUserSubscriptions';

    this.loaderService.show(key);

    return this.adminService.getUserSubscriptions(this.customerId).pipe(
      catchError(e => {
        console.dir(e);
        
        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.hide(key);
      })
    );
  }

  fetchTrialUserSubscription(): Observable<SubscriptionModel> {
    const key = 'AdminCustomerInfoComponent_fetchTrialUserSubscription';
    
    this.loaderService.show(key);

    return this.adminService.fetchTrialCustomerSubscription(this.customerId).pipe(
      catchError(e => {
        console.dir(e);

        return throwError(() => e);
      }),
      map((res: SubscriptionDto) => SubscriptionDto.normalize(res)),
      finalize(() => {
        this.loaderService.hide(key);
      })
    );
  }

  public redirectToCouponsAdminPage(): void {
    this.navigationService.toAdminStripe();
  }

  public addUserCoupon(): void {
    this.couponError = '';

    if (!this.selectedCouponId || this.selectedCouponId === DEFAULT_COUPON_ID) {
      this.couponError = 'Please, select coupon.';

      return;
    }

    this.accountsCouponsHttpService.createUserCoupon(this.customer.Id, this.selectedCouponId);
  }

  public addSupportMeta(): void {
    this.newSupportItem.validate();

    this.supportError = null;

    if (!this.newSupportItem.isValid) return;

    this.adminSupportMetaService.create(this.customerId, this.newSupportItem).add(() => {
      this.fetchSupportItems();

      this.newSupportItem = new SupportMetaItemModel(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        SUPPORT_STATUSES_KEYS.VALID,
        null,
        moment().format(TIME_FORMATTERS.DATE),
      );

      if (!this.adminSupportMetaService.createError) return;

      this.supportError = this.adminSupportMetaService.createError.error ? this.adminSupportMetaService.createError.error.message : 'Something went wrong.';
    });
  }

  public updateSupportItem(item: SupportMetaItemModel): void {
    this.adminSupportMetaService.updateNotes(this.customerId, item).add(() => {
      this.fetchSupportItems();
    });
  }

  public onSupportRowEditConfirm(item: SupportMetaItemModel): void {
    item.validate();

    if (!item.isValid) return;

    item.isEditing = false;

    this.adminSupportMetaService.update(this.customerId, item).add(() => {
      this.fetchSupportItems();
    });
  }

  public onSupportRowEdit(item: SupportMetaItemModel): void {
    item.isEditing = true;
  }

  public onSupportRowDelete(item: SupportMetaItemModel): void {
    this.removeSupportRowModalService.open();

    this.removeSupportRowModalService.isDeleteConfirmed.pipe(take(1)).subscribe((isConfirmed: boolean) => {
      if (!isConfirmed) return;

      this.adminSupportMetaService.remove(this.customerId, item).add(() => {
        this.fetchSupportItems();
      });
    });
  }

  public sort(column: string): void {
    const oldSortBy: string = this.supportSortBy;

    this.supportSortBy = column;
    this.isSupportSortUp = oldSortBy === this.supportSortBy ? !this.isSupportSortUp : true;

    this.fetchSupportItems();
  }

  private fetchSupportItems(): void {
    this.adminSupportMetaService.getAllForUser(this.customerId, this.supportSortBy, this.isSupportSortUp);
  }

  public openDeleteModal(coupon: AccountCouponModel): void {
    this.isCouponRemoving = true;

    this.couponToDelete = coupon;
  }

  public submitCouponRemoving(): void {
    this.isCouponRemoving = false;

    this.accountsCouponsHttpService.deleteUserCoupon(this.couponToDelete);
  }

  public cancelCouponRemoving(): void {
    this.isCouponRemoving = false;
  }

  public showIsCustomerImportedModal(): void {
    this.isPurchaseTabsToggling = true;
  }

  public toggleIsCustomerImported(): void {
    if (!this.customer) return;

    this.customer.IsUserImported = !this.customer.IsUserImported;

    this.hideIsCustomerImportedModal();

    this.adminService.updateIsUserImported(this.customerId, this.customer.IsUserImported).pipe(
      catchError(e => {
        this.customer.IsUserImported = !this.customer.IsUserImported;

        return throwError(() => e);
      })
    ).subscribe(() => {});
  }

  public toggleIsImageFilesRemoveEnabled(): void {
    if (!this.customer) return;

    this.customer.IsImageFilesRemoveEnabled = !this.customer.IsImageFilesRemoveEnabled;

    this.hideIsCustomerImportedModal();

    this.adminService.updateIsImageFilesRemoveEnabled(this.customerId, this.customer.IsImageFilesRemoveEnabled).subscribe(
      () => {},
      () => {
        this.customer.IsImageFilesRemoveEnabled = !this.customer.IsImageFilesRemoveEnabled;
      }
    );
  }

  public toggleIsMessagesReviewEnabled(): void {
    if (!this.customer) return;

    this.customer.IsMessagesReviewEnabled = !this.customer.IsMessagesReviewEnabled;

    this.adminService.updateIsMessagesReviewEnabled(this.customerId, this.customer.IsMessagesReviewEnabled).subscribe(
      () => {},
      () => {
        this.customer.IsMessagesReviewEnabled = !this.customer.IsMessagesReviewEnabled;
      }
    );
  }

  public hideIsCustomerImportedModal(): void {
    this.isPurchaseTabsToggling = false;
  }

  public removeFailedImages(): void {
    if (!this.customer) return;

    this.failedImagesHandlingModalService.open();

    this.adminService.removeFailedImages(this.customerId).subscribe(
      () => {

      },
      () => {

      }
    );
  }

  public onCustomSubscriptionSet() {
    const key = 'AdminCustomerInfoComponent_onCustomSubscriptionSet';

    this.customSubscriptionError = null;
    
    this.loaderService.show(key);

    this.adminService.addCustomSubscriptionToUser(this.customerId, this.newCustomSubscription.id).pipe(
      catchError(e => {
        console.error(e);
  
        this.initCustomSubscriptionError(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.hide(key);
      }),
    ).subscribe(() => {
      this.fetchUserSubscriptions();
    });
  }

  public onCustomSubscriptionDelete(accountSubscriptionId: number) {
    const key = 'AdminCustomerInfoComponent_onCustomSubscriptionDelete';

    this.customSubscriptionError = null;

    this.loaderService.show(key);

    const onSuccess = () => {
      this.fetchUserSubscriptions();

      this.loaderService.hide(key);
    };

    this.adminService.deleteCustomSubscriptionFromUser(this.customerId, accountSubscriptionId).pipe(
      catchError(e => {
        console.dir(e);
  
        this.initCustomSubscriptionError(e);
  
        this.loaderService.hide(key);

        return throwError(() => e);
      }),
    ).subscribe(onSuccess);
  }

  private initCustomSubscriptionError(err: HttpErrorResponse): void {
    try {
      this.customSubscriptionError = err.error.message;
    } catch (e) {
      this.customSubscriptionError = 'Something went wrong.';
    }
  }

  public onTrialEndChange(e): void {
    this.trialEnd = e.currentTarget.value;
  }

  public addTrial() {
    const trialEnd: string = moment(this.trialEnd).format(TIME_FORMATTERS.DATE);

    this.trialError = null;

    this.adminService.setTrialSubscription(this.customerId, trialEnd).pipe(
      catchError(e => {
        this.trialError = e && e.error && e.error.message ? e.error.message : '';

        return throwError(() => e);
      })
    ).subscribe(() => {
      this.fetchUserSubscriptions();
    });
  }

  public updateTrial() {
    const trialEnd: string = moment(this.trialEnd).format(TIME_FORMATTERS.DATE);

    this.trialError = null;

    this.adminService.updateTrialEndDate({
      userId: this.customerId,
      subscriptionId: this.trialSubscription.id,
      trialEnd,
    }).pipe(
      catchError(e => {
        this.trialError = e && e.error && e.error.message ? e.error.message : '';

        return throwError(() => e);
      })
    ).subscribe(() => {
      this.fetchUserSubscriptions();
    });
  }

  public saveSupportSetup(): void {
    this.setSupportSetupSaveStatus('processing');

    this.adminService.updateSupportVideoLink(this.customerId, this.customer.SupportVideoLink).subscribe(
      () => this.setSupportSetupSaveStatus('success'),
      () => this.setSupportSetupSaveStatus('error'),
    );
  }

  private setSupportSetupSaveStatus(status: string): void {
    window.clearTimeout(this.setSupportSetupSaveStatusTimeoutId);

    this.supportSetupSaveStatus = status;

    this.setSupportSetupSaveStatusTimeoutId = window.setTimeout(() => {
      this.supportSetupSaveStatus = null;
    }, 2000);
  }

  public onPauseSubscriptionInputFocus(): void {
    this.isPauseSubscriptionInputFocused = true;
  }

  public onPauseSubscriptionInputBlur(): void {
    this.isPauseSubscriptionInputFocused = false;
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
