import {Component, OnInit, OnDestroy, NgZone, ViewChild, HostListener, ChangeDetectorRef} from '@angular/core';

import {Observable, Subject, Subscription, forkJoin, of, throwError} from 'rxjs';
import {catchError, finalize, switchMap, take, takeUntil, tap} from 'rxjs/operators';

import * as _ from 'lodash';

import {WebsiteDesignerService} from '../website-designer.service';
import {BagService} from '../../../../bag.service';
import {BlocksService} from '../../../sidebar-short/sidebar/blocks/blocks.service';
import {ModalsService} from '../../../../shared/services/modals/modals.service';
import {DetailsService} from '../../details/details.service';
import {EditorControlButtonsService} from '../../../../services/editor-control-buttons.service';
import {PagesService} from '../../../sidebar-short/sidebar/pages/pages.service';
import {NavigationService} from '../../../../services/navigation.service';
import {CanLeaveComponent} from '../../../../shared/services/guards/can-leave-component-guard.service';
import {ResizeService} from '../../../../services/editor-resize-handler.service';
import {IFrameMenuService} from '../../../../core/services/iframe/menu/menu.service';
import {PageEditorService} from './page-editor.service';
import {PortfolioPickerModalService} from '../../../../services/portfolio-picker-modal.service';
import {BlockHelpService} from '../../../../core/services/iframe/block-help/block-help.service';
import {AuthService} from '../../../../auth/auth.service';
import {LogoUploadService} from '../../../../core/services/iframe/logo-upload/logo-upload.service';
import {IFrameService} from '../../../../core/services/iframe/iframe.service';
import {ButtonsService} from '../../../../core/services/buttons/buttons.service';
import {ThemesService} from '../theme-pane/themes.service';
import {CustomizerMobileTemplatesService} from '../../../../core/services/customizer/mobile/templates/customizer-mobile-templates.service';
import {DocumentManagerService} from '../../../../core/services/document-manager/document-manager.service';
import {VideoManagerService} from '../../../../core/services/video-manager/video-manager.service';
import {IFrameRoutingService} from '../../../../core/services/iframe/routing/iframe-routing.service';
import {EventsService} from '../../../../core/services/interaction/events/events.service';
import {NodesService} from '../../../../core/services/nodes/nodes.service';
import {SidebarCustomizerElementService} from '../../../../core/services/sidebar/customizer/element/element.service';
import {SelectedBlocksService} from '../../../../services/selected-blocks.service';
import {PrivatePagePasswordModalService} from '../../../../shared/services/modals/private-page-password/private-page-password-modal.service';
import {DirectLinkInputModalService} from '../../../../shared/services/modals/direct-link-input/direct-link-input-modal.service';
import {TextAlertModalService} from '../../../../services/text-alert-modal.service';
import {GoogleFontsService} from '../../../../core/services/google/fonts/google-fonts.service';
import {SocketsService} from '../../../../core/services/interaction/sockets/sockets.service';
import {SidebarSectionsService} from '../../../../services/sidebar-sections.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';
import {TemplatesService} from '../../../../core/services/templates/templates.service';
import {DefaultPortfolioHttpService} from '../../../../core/services/default-portfolio/http/default-portfolio.http.service';
import {DefaultPortfolioService} from '../../../../core/services/default-portfolio/default-portfolio.service';
import {DefaultPortfolioSetupModalService} from '../../../../shared/services/modals/default-portfolio-setup/default-portfolio-setup-modal.service';
import {WebsiteBlocksService} from '../../../../core/services/websites/blocks/website-blocks.service';
import {CustomHomePageModalService} from '../../../../shared/services/modals/custom-home-page/custom-home-page-modal.service';
import {EducationImageManagerService} from '../../../../core/services/education/image-manager/education-image-manager.service';
import { ImageManagerModalService } from '../../../../shared/components/modals/image-manager-modal/image-manager-modal.service';
import { ImageResolutionService } from '../../../../services/image-resolution.service';
import { SetImageModalService } from '../../../../shared/services/modals/set-image/set-image-modal.service';

import {NodeModel} from '../../../../core/models/nodes/node.model';
import {SelectedPageModel} from '../../../../core/models/selected-page/selected-page.model';
import {WebsiteFontsDataModel} from '../../../../core/models/google/fonts/website-fonts-data.model';
import {GoogleFontModel} from '../../../../core/models/google/fonts/google-font.model';
import {WebsiteModel} from '../../../../core/models/websites/website.model';
import {TemplateModel} from '../../../../core/models/templates/template.model';
import {ModalDataModel} from '../../../../core/models/modals/modal-data.model';

import {EVENTS, PORTFOLIO_ID_SUPPORTS_SLIDES} from './constants';
import {BUTTONS_KEYS} from '../../../../core/services/buttons/constants';
import {PUBLISH_STATUSES} from '../../../../services/publish-website/constants';

import {AppAnimations} from '../../../../app-animations';
import { ChangesMonitorService } from '../../../../shared/services/changes-monitor/changes-monitor.service';
import { SaveOptionsModalService } from '../../../../shared/services/modals/save-options/save-options-modal.service';
import {KEYS as SAVE_OPTIONS_KEYS} from '../../../../shared/services/modals/save-options/constants';

const LOADING_EVENTS = {
  UPDATING_TEMPLATE: 'updating-template',
  LOADING_PAGE_LAST_BLOCKS: 'loading-page-last-blocks',
  SAVING_BLOCKS: 'saving-blocks',
  SPLASH_TOGGLING: 'splash-toggling',
};

@Component({
  selector: 'app-page-editor',
  templateUrl: './page-editor.component.html',
  styleUrls: ['./page-editor.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class PageEditorComponent implements OnInit, OnDestroy, CanLeaveComponent {
  @ViewChild('editorFrame') editorFrame;

  public readonly SAVE_REQUEST_MODAL_ID = 'save-request-modal-on-page-editor';
  public readonly ERROR_MODAL_ID = 'error-modal-on-page-editor';

  public isFrameLoaded: boolean = false;
  public isDataLoaded: boolean;
  public isTemplateBeingUpdated: boolean = false;
  public isUpcomingDefaultPortfolioSupportsSlides: boolean = false;

  public sandboxUrl: string;

  public templateId: number = null;

  public currentNodeData = null;

  public modalsStatuses: { [key: string]: ModalDataModel } = {};

  public loadingEvent: string;

  public selectedPage: SelectedPageModel;

  public errorMessage: string;

  private _openedPage: SelectedPageModel = SelectedPageModel.getClear();

  private blocksData: { blocks: any[], pageData: { id: number, type: string, isSplash: boolean } };

  private splashData = {
    id: null,
    type: null,
    hasSplash: false,
    showSplash: false
  };

  private injectedData: any = null;

  private website: WebsiteModel;

  private nodes: NodeModel[] = [];

  private websiteFontData: WebsiteFontsDataModel = null;
  private usedFonts: string[] = [];

  private currentPageBlocksPromise: Observable<any> = null;

  private defaultPortfolio: {
    blockTemplateId: number,
    blockCategory: string,
    name: string,
    version: number,
    usedFonts: string,
    html: string,
  };

  private canLeaveComponentSubject: Subject<boolean> = new Subject<boolean>();
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  private loadingState: any = {};

  private isEditorReady = false;
  private isBlocksLoaded = false;
  private isBlocksLoadedToEditor = true;
  private isSplashPageEditing: boolean = false;
  private isUsedFontsInitCompleted: boolean = false;
  private isCustomMenuBlock: boolean = false;

  private handlers: { [key: string]: any } = {
    [EVENTS.OPEN_CUSTOMIZER]: this.openCustomizer.bind(this),
    [EVENTS.CLOSE_CUSTOMIZER]: this.closeCustomizer.bind(this),
    [EVENTS.OPEN_PORTFOLIO]: this.openPortfolio.bind(this),
    [EVENTS.FORCE_SAVE_PAGE]: this.saveHandler.bind(this),
    [EVENTS.SAVE_WEBSITE_TITLE]: this.saveWebsiteTitle.bind(this),
    [EVENTS.SAVE_WEBSITE_SUBTITLE]: this.saveWebsiteSubtitle.bind(this),
    [EVENTS.OPEN_PORTFOLIO_SELECT_MODAL]: this.openPortfolioSelectModal.bind(this),
    [EVENTS.DROPDOWN_TOO_HIGH]: this.onDropdownTooHigh.bind(this),
    [EVENTS.UPDATE_DROPPED_BLOCKS]: this.onDroppedBlocksUpdate.bind(this),
    [EVENTS.SAVE_AS_DEFAULT_PORTFOLIO]: this.saveAsDefaultPortfolio.bind(this),
    [EVENTS.OPEN_IMAGE_MANAGER]: this.openImageManager.bind(this),
  };

  public get isLoaded(): boolean {
    return this.isFrameLoaded && this.isDataLoaded;
  }

  public get openedPage(): SelectedPageModel {
    return this._openedPage;
  }
  public set openedPage(page: SelectedPageModel) {
    this.selectedPage = page;

    const id: number = page ? page.id || null : null;
    const type: string = page ? page.type || null : null;
    const isSplash: boolean = page ? page.isSplash || null : null;

    if (!this._openedPage) {
      this._openedPage = SelectedPageModel.getClear();
    }

    if (this._openedPage.id !== id
      || this._openedPage.type !== type
      || this._openedPage.isSplash !== isSplash) {
      this._openedPage.clear();

      this._openedPage.id = id;
      this._openedPage.type = type;
      this._openedPage.isSplash = isSplash;
    }

    this.updateCurrentNodeData();
  }

  public get defaultPortfolioSetupModalId(): string {
    return this.defaultPortfolioSetupModalService.id;
  }

  public get customHomePageModalId(): string {
    return this.customHomePageModalService.id;
  }

  private get isPageValid(): boolean {
    return !!this.openedPage && !!this.openedPage.id && !!this.openedPage.type;
  }

  private get pageMeta(): { WebsiteID: number, TemplateID: number, PageID: number, PageType: string, saveType?: string } {
    return {
      PageID: this.openedPage.id,
      PageType: this.isSplashPageEditing ? 'S' : this.openedPage.type,
      TemplateID: this.templateId,
      WebsiteID: this.website.id,
    };
  }

  constructor(
    private zone: NgZone,
    private cdr: ChangeDetectorRef,
    private websiteDesignerService: WebsiteDesignerService,
    private detailsService: DetailsService,
    private pagesService: PagesService,
    private nodesService: NodesService,
    private navigationService: NavigationService,
    private eventsService: EventsService,
    private iFrameService: IFrameService,
    private iFrameRoutingService: IFrameRoutingService,
    private iFrameMenuService: IFrameMenuService,
    private documentManagerService: DocumentManagerService,
    private videoManagerService: VideoManagerService,
    private pageEditorService: PageEditorService,
    private portfolioPickerModalService: PortfolioPickerModalService,
    private privatePagePasswordModalService: PrivatePagePasswordModalService,
    private directLinkInputModalService: DirectLinkInputModalService,
    private blockHelpService: BlockHelpService,
    private authService: AuthService,
    private logoUploadService: LogoUploadService,
    private templatesService: TemplatesService,
    private buttonsService: ButtonsService,
    private customizerMobileTemplatesService: CustomizerMobileTemplatesService,
    private elementService: SidebarCustomizerElementService,
    private selectedBlocksService: SelectedBlocksService,
    private textAlertModalService: TextAlertModalService,
    private googleFontsService: GoogleFontsService,
    private sidebarSectionsService: SidebarSectionsService,
    private socketsService: SocketsService,
    private websitesService: WebsitesService,
    private defaultPortfolioHttpService: DefaultPortfolioHttpService,
    private defaultPortfolioService: DefaultPortfolioService,
    private defaultPortfolioSetupModalService: DefaultPortfolioSetupModalService,
    private customHomePageModalService: CustomHomePageModalService,
    private websiteBlocksService: WebsiteBlocksService,
    private educationImageManagerService: EducationImageManagerService,
    private imageManagerModalService: ImageManagerModalService,
    private imageResolutionService: ImageResolutionService,
    private setImageModalService: SetImageModalService,
    private changesMonitorService: ChangesMonitorService,
    private saveOptionsModalService: SaveOptionsModalService,
    public bag: BagService,
    public modalsService: ModalsService,
    public blockService: BlocksService,
    public editorResizeService: ResizeService,
    public editorControlButtonsService: EditorControlButtonsService,
    public themesService: ThemesService,
  ) {
    this.addListeners();
  }

  private addListeners(): void {
    this.selectedBlocksService.init();

    this.eventsService.addFrameListener(EVENTS.OPEN_CUSTOMIZER, this.handlers[EVENTS.OPEN_CUSTOMIZER]);
    this.eventsService.addFrameListener(EVENTS.CLOSE_CUSTOMIZER, this.handlers[EVENTS.CLOSE_CUSTOMIZER]);
    this.eventsService.addFrameListener(EVENTS.OPEN_PORTFOLIO, this.handlers[EVENTS.OPEN_PORTFOLIO]);
    this.eventsService.addFrameListener(EVENTS.FORCE_SAVE_PAGE, this.handlers[EVENTS.FORCE_SAVE_PAGE]);
    this.eventsService.addFrameListener(EVENTS.SAVE_WEBSITE_TITLE, this.handlers[EVENTS.SAVE_WEBSITE_TITLE]);
    this.eventsService.addFrameListener(EVENTS.SAVE_WEBSITE_SUBTITLE, this.handlers[EVENTS.SAVE_WEBSITE_SUBTITLE]);
    this.eventsService.addFrameListener(EVENTS.OPEN_PORTFOLIO_SELECT_MODAL, this.handlers[EVENTS.OPEN_PORTFOLIO_SELECT_MODAL]);
    this.eventsService.addFrameListener(EVENTS.DROPDOWN_TOO_HIGH, this.handlers[EVENTS.DROPDOWN_TOO_HIGH]);
    this.eventsService.addFrameListener(EVENTS.UPDATE_DROPPED_BLOCKS, this.handlers[EVENTS.UPDATE_DROPPED_BLOCKS]);
    this.eventsService.addFrameListener(EVENTS.SAVE_AS_DEFAULT_PORTFOLIO, this.handlers[EVENTS.SAVE_AS_DEFAULT_PORTFOLIO]);
    this.eventsService.addFrameListener(EVENTS.OPEN_IMAGE_MANAGER, this.handlers[EVENTS.OPEN_IMAGE_MANAGER]);
  }

  private openCustomizer(e: CustomEvent): void {
    this.websiteDesignerService.setEditingObject(e.detail);
  }

  private closeCustomizer(e: CustomEvent): void {
    if (!e.detail.element || e.detail.element.contains(this.elementService.element)) {
      return this.websiteDesignerService.setEditingObject(null);
    }
  }

  private openPortfolio(e: CustomEvent): void {
    this.educationImageManagerService.setActiveTab('user', { unlock: true });
    
    this.navigationService.toImageManager({ id: e.detail.portfolioId, type: 'P' });
  }

  private saveWebsiteTitle(e: CustomEvent): void {
    this.websiteDesignerService.updateWebsite({ Title: e.detail }).pipe(
      catchError(e => {
        this.errorMessage = e?.error?.message || `Error on title save`;
    
        this.modalsService.open(this.ERROR_MODAL_ID);

        return throwError(() => e);
      })
    ).subscribe(() => this.reloadInjectedData());
  }

  private saveWebsiteSubtitle(e: CustomEvent): void {
    this.websiteDesignerService.updateWebsite({ Subtitle: e.detail }).pipe(
      catchError(e => {
        this.errorMessage = e?.error?.message || `Error on subtitle save`;
    
        this.modalsService.open(this.ERROR_MODAL_ID);

        return throwError(() => e);
      })
    ).subscribe(() => this.reloadInjectedData());
  }

  private openPortfolioSelectModal(e: CustomEvent): void {
    this.elementService.element = e.detail.block;

    this.openPortfolioPickerModal();
  }

  private onDropdownTooHigh(): void {
    this.textAlertModalService.show({
      message: 'Your dropdown menu height is too high and might be not suitable for all devices. We recommend using different menu or changing pages layout.'
    });
  }

  private onDroppedBlocksUpdate(e: CustomEvent): void {
    this.blockService.updateDroppedBlocks(e.detail);
  }

  private saveAsDefaultPortfolio(): void {
    this.defaultPortfolio = this.blockService.getDefaultPortfolio();

    this.isUpcomingDefaultPortfolioSupportsSlides = this.defaultPortfolio && PORTFOLIO_ID_SUPPORTS_SLIDES.includes(this.defaultPortfolio.blockTemplateId);

    this.defaultPortfolioSetupModalService.open();
  }

  private openImageManager(e: CustomEvent): void {
    const element: HTMLElement = e.detail.element;
    const block: HTMLElement = element ? <HTMLElement>element.closest('.block') : null;

    this.imageManagerModalService.blockedPortfolioId.next(
      element
        ? Number.parseInt(element.getAttribute('data-portfolio-id'))
        : null
    );

    this.imageManagerModalService.isRandomizedSubject.next({
      element,
      block,
      isRandomizable: block ? block.matches('[data-is-randomizable="true"]') : false,
      isRandomized: block ? block.getAttribute('data-is-randomizable-value') === 'true' : false,
    });

    this.imageResolutionService.setImageResolutionRequirements(e.detail.imageResolution);

    this.setImageModalService.init(element);

    this.imageManagerModalService.open();
  }

  public ngOnInit(): void {
    this.iFrameService.isLoadedSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(isLoaded => {
      this.isFrameLoaded = isLoaded;

      if (!isLoaded) return;

      this.onEditorReady();
      this.updatePageEditor();
    });

    this.authService.onSignOut.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this._openedPage = null;
      this.injectedData = null;
      this.sandboxUrl = null;

      this.cdr.detectChanges();
    });

    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.cdr.detectChanges();
    });

    this.pageEditorService.isCustomMenuBlock.pipe(takeUntil(this.ngUnsubscribe)).subscribe((isCustomMenuBlock: boolean) => {
      this.isCustomMenuBlock = isCustomMenuBlock;
    });

    this.websiteDesignerService.injectedDataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(injectedData => {
      this.injectedData = injectedData;
    });

    this.pageEditorService.reload.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.refreshEditorFrame();
    });

    this.iFrameRoutingService.srcSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(src => {
      this.sandboxUrl = src;

      this.cdr.detectChanges();
    });

    this.iFrameRoutingService.selectedPageSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((selectedPage: SelectedPageModel) => {
      this.websiteDesignerService.selectedPage = selectedPage;

      const oldPage = this.selectedPage ? SelectedPageModel.clone(this.selectedPage) : null;

      this.openedPage = selectedPage;

      this.isSplashPageEditing = selectedPage && selectedPage.isHomePage && selectedPage.isSplash;

      if (!selectedPage || !selectedPage.id || (oldPage && selectedPage.isSameTo(oldPage))) {
        return;
      }

      this.buttonsService.setSaveButtonVisibility(true);
      this.buttonsService.setCancelButtonVisibility(true);

      this.websiteDesignerService.getInjectedData(selectedPage);

      this.websiteDesignerService.injectedDataSubject.next(null);

      this.getInjectedData(true);
    });

    this.websiteDesignerService.forceSaveChangesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.saveHandler();
    });

    this.googleFontsService.websiteDataSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((websiteFontData: WebsiteFontsDataModel) => {
      this.websiteFontData = websiteFontData;

      this.initFonts();
    });

    this.templatesService.activeTemplateSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((template: TemplateModel) => {
      this.templateId = template ? template.id : null;
    });

    this.modalsService.statusSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((statuses: { [key: string]: ModalDataModel }) => {
      this.modalsStatuses = statuses;

      this.cdr.detectChanges();
    });

    this.setDefaultElementToStyleEditor();
    this.addApiForEditor();

    this.addSubscriptions();
  }

  private addSubscriptions(): void {
    this.editorControlButtonsService.controlBtnClick.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.onControlButtonClick.bind(this));

    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.handleWebsite.bind(this));

    this.detailsService.resetClick.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.saveBlocks();
    });

    this.websiteDesignerService.checkForPageChangesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.handleCheckForPageChanges.bind(this));

    this.nodesService.nodesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((nodes: NodeModel[]) => {
      this.nodes = nodes;

      this.updateCurrentNodeData();
      this.updateSplashInfo();
    });

    this.templatesService.isTemplateBeingUpdated.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value: boolean) => {
      this.isTemplateBeingUpdated = value;
    });

    this.portfolioPickerModalService.selectedPortfolioId.pipe(takeUntil(this.ngUnsubscribe)).subscribe(portfolioId => {
      this.buttonsService.enableSaveButton();
      this.eventsService.dispatchPortfolioSelect({ portfolioId, target: this.elementService.element }, this.iFrameService.sandboxWindow);
    });
  }

  private updateCurrentNodeData() {
    if (!this.isPageValid || this.nodes.length === 0) return;

    const { id, type } = this.openedPage;

    this.currentNodeData = this.nodes.find(node => node.id === id && node.type === type);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.editorResizeService.onResize();
  }

  handleWebsite(website: WebsiteModel) {
    if (!website) return;

    this.website = website;
  }

  getStylesForceApplyValue() {
    return this.detailsService.forceApplyStyles;
  }

  updateSplashInfo() {
    const homeNode: NodeModel = this.nodes.find((node: NodeModel) => node.isHomePage);

    if (!homeNode) {
      return;
    }

    this.websiteDesignerService.getSplashInfo(homeNode.id, homeNode.type).subscribe(splashData => {
      this.splashData.hasSplash = splashData && splashData.hasSplash;
      this.splashData.showSplash = splashData && splashData.showSplash;
    });
  }

  onControlButtonClick(data) {
    const buttonName = data.buttonName;
    const controlButtons = this.editorControlButtonsService.CONTROL_BUTTONS;

    switch (buttonName) {
      case (controlButtons.SAVE):
        this.saveBlocks();
        break;
      case (controlButtons.CANCEL):
        this.nodesService.reInitSplashVisibility().add(() => {
          this.blockService.blocksToMoveToAnotherPage = [];
  
          this.refreshEditorFrame();
        });
      case (controlButtons.TOGGLE_SPLASH_PAGE):
        this.handleSplashToggle(data.value);
        break;
    }
  }

  private refreshEditorFrame() {
    const { id, type } = this.openedPage;

    this.eventsService.dispatchCancelChanges(null, this.iFrameService.sandboxWindow);

    this.iFrameService.isLoadedSubject.next(false);
    this.iFrameService.onContentLoad.next(false);

    this.detailsService.reloadPageDetails(this.website.id, this.templateId, id, type).add(() => {
      this.iFrameService.reload();
    });
  }

  // adds object with current component and zone to window, iframe app can use them to invoke methods from angular app
  addApiForEditor() {
    (<any> window).angularComponentRef = {
      zone: this.zone,
      component: this
    };
  }

  getBlockHelpText({textOptions, blockOptions}) {
    return this.blockHelpService.getText(textOptions, blockOptions);
  }

  private reloadInjectedData() {
    this.injectedData = null;

    this.websiteDesignerService.getInjectedData(this.selectedPage).add(() => {
      return this.getInjectedData(true);
    });
  }

  async getInjectedData(reload?) {
    return new Promise(resolve => {
      if (!reload && this.injectedData && this.selectedPage) {
        this.injectedData.pageId = this.selectedPage.id;
        this.injectedData.pageType = this.selectedPage.type;
        this.injectedData.nodes = this.nodes;

        resolve({
          sandbox: true,
          ...this.injectedData,
          ...this.customizerMobileTemplatesService.values,
        });

        return;
      }

      const unsubscribe: Subject<boolean> = new Subject<boolean>();

      return this.websiteDesignerService.injectedDataSubject.pipe(takeUntil(unsubscribe)).subscribe(injectedData => {
        if (!injectedData || this.selectedPage.id !== injectedData.pageId) {
          return;
        }

        resolve(injectedData);

        unsubscribe.next(true);
        unsubscribe.complete();
      });
    });
  }

  openPortfolioPickerModal() {
    this.portfolioPickerModalService.show();
  }

  updateDroppedBlocks(droppedBlocksCategories) {
    this.blockService.updateDroppedBlocks(droppedBlocksCategories);
  }

  // This method used to notify customizer that menu was built.
  // So customizer could parse last styles from menu. (Except saved styles it has default styles).
  handleMenuIsReady() {
    this.detailsService.menuReadySubject.next(true);
  }

  updatePageEditor(): void {
    if (!this.openedPage || !this.templateId || !this.openedPage.id || !this.openedPage.type) return;

    return this.getCurrentPageBlocks();
  }

  setDefaultElementToStyleEditor() {
    this.websiteDesignerService.setEditingObject(null);
  }

  getCurrentPageBlocks() {
    this.startLoading(LOADING_EVENTS.LOADING_PAGE_LAST_BLOCKS);

    const pageData = Object.assign({}, this.openedPage);

    this.fetchCurrentPageBlocks(pageData.id, pageData.type, true).pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
      finalize(() => {
        this.stopLoading(LOADING_EVENTS.LOADING_PAGE_LAST_BLOCKS);
      })
    ).subscribe(blocks => {
      if (this.openedPage.templateId !== pageData.templateId ||this.openedPage.id !== pageData.id ||this.openedPage.type !== pageData.type || this.openedPage.isSplash !== pageData.isSplash) return;

      this.blocksData = {
        blocks,
        pageData,
      };

      this.isBlocksLoadedToEditor = false;
      this.isBlocksLoaded = true;

      this.editorReadyHandler();
    })
  }

  private fetchCurrentPageBlocks(id, type, reload?): Observable<any> {
    if (!reload && this.currentPageBlocksPromise) {
      return this.currentPageBlocksPromise;
    }

    if (!this.website) {
      return of([]);
    }

    this.currentPageBlocksPromise = this.fetchBlocks({ id, type });

    return this.currentPageBlocksPromise;
  }

  private fetchBlocks({ id, type }: { id: number, type: string }): Observable<any> {
    return this.websiteBlocksService.getCurrentPageBlocks({
      websiteId: this.website.id,
      templateId: this.website.templateId,
      pageId: id,
      pageType: type,
    });
  }

  startLoading(key) {
    this.toggleLoading(key, true);
  }

  stopLoading(key) {
    this.toggleLoading(key, false);
  }

  toggleLoading(key: string, value: boolean) {
    this.loadingEvent = value ? key : null;

    this.loadingState[key] = value;

    this.isDataLoaded = !this.isLoadingPerforms();

    this.pageEditorService.isEditorLoaded = this.isLoaded;
  }

  private isLoadingPerforms() {
    return Object.keys(this.loadingState).some(key => this.loadingState[key]);
  }

  onStyleEditor(data) {
    this.websiteDesignerService.setEditingObject(data);
  }

  addEditorLoadingHandler() {
    const window = <any>this.iFrameService.sandboxWindow;

    if (!window) return;

    window.addOnLoadingHandlers(this.onEditorLoading.bind(this));
  }

  onEditorLoading(key, value) {
    this.toggleLoading(key, value);
  }

  onEditorReady() {
    this.addEditorLoadingHandler();

    this.isEditorReady = true;

    return this.editorReadyHandler();
  }

  editorReadyHandler() {
    if (!this.isEditorReady) return;

    this.addBlocksToEditor(this.blocksData);
  }

  getBlocksToSave() {
    return this.editorFrame.nativeElement.contentWindow.getBlocksToSave();
  }

  getConvertedBlocks(meta: { WebsiteID: number, TemplateID: number, PageID: number, PageType: string, saveType?: string }) {
    const blocks = this.getBlocksToSave();
    const handledBlocks = this.isSplashPageEditing ? blocks.filter(block => block.data.BlockCategoryId === 34) : blocks;
    const convertedBlocks = this.blockService.convertBlockDataToSave(handledBlocks);

    if (meta.saveType === SAVE_OPTIONS_KEYS.THIS_PAGE) {
      const menuBlocks = convertedBlocks.filter(block => block.Block_Category === 'Menus');

      menuBlocks.forEach(menu => {
        menu.PageID = meta.PageID;
        menu.PageType = meta.PageType;
      });
    }

    return convertedBlocks;
  }

  private saveBlocks(): Subscription {
    if (!this.isLoaded) {
      return Subscription.EMPTY;
    }

    const isChanged: boolean = this.changesMonitorService.isChanged();

    this.startLoading(LOADING_EVENTS.SAVING_BLOCKS);

    const pageMeta = this.pageMeta;
    
    pageMeta.saveType = SAVE_OPTIONS_KEYS.WEBSITE_DEFAULTS;

    if (isChanged) {
      this.saveOptionsModalService.open({
        isThisPageSave: this.isCustomMenuBlock,
      });

      return this.saveOptionsModalService.observable.pipe(
        switchMap(({ value, isRejected }) => {
          if (isRejected) {
            this.stopLoading(LOADING_EVENTS.SAVING_BLOCKS);

            return throwError(() => {
              return {
                reason: 'Changes were not saved because of "cancel" action happened in modal',
              };
            });
          }
    
          pageMeta.saveType = value;

          return this.doDataSave(pageMeta);
        })
      ).subscribe(() => {
        this.socketsService.publishDataSubject.next({ status: PUBLISH_STATUSES.READY, websiteId: this.websitesService.activeWebsiteId });
  
        this.websiteDesignerService.onChangesSaved.next(true);
      });
    }

    return this.doDataSave(pageMeta).subscribe(() => {
      this.socketsService.publishDataSubject.next({ status: PUBLISH_STATUSES.READY, websiteId: this.websitesService.activeWebsiteId });

      this.websiteDesignerService.onChangesSaved.next(true);
    });
  }

  private doDataSave(pageMeta): Observable<any> {
    const convertedBlocks = this.getConvertedBlocks(pageMeta);

    const list: Observable<any>[] = [
      ...this.pageEditorService.saveBlocksAndDetails(pageMeta, convertedBlocks),
      this.saveShowSplashInfo(),
    ];

    return forkJoin(list).pipe(
      catchError(e => {
        console.error(e);
        
        this.canLeaveComponentSubject.next(false);
        
        this.buttonsService.enableSaveButton();

        return throwError(() => e);
      }),
      finalize(() => {
        this.changesMonitorService.clean();

        this.stopLoading(LOADING_EVENTS.SAVING_BLOCKS);

        if (pageMeta.saveType === SAVE_OPTIONS_KEYS.THIS_PAGE) {
          this.refreshEditorFrame();
        }
      }),
    );
  }
  
  private saveShowSplashInfo(): Observable<any> {
    if (this.splashData.id && this.splashData.type) {
      return this.websiteDesignerService.toggleSplash(this.templateId, this.splashData.id, this.splashData.type, this.splashData.showSplash);
    }

    return of(null);
  }

  public onDefaultPortfolioSave(isApplyToAll: boolean) {
    this.saveDefaultPortfolio(isApplyToAll);

    this.buttonsService.enableSaveButton();
  }

  private saveDefaultPortfolio(isApplyToAllPortfolios: boolean) {
    this.defaultPortfolioService.save({
      websiteId: this.website.id,
      templateId: this.templateId,
      blockTemplateId: this.defaultPortfolio.blockTemplateId,
      blockCategory: this.defaultPortfolio.blockCategory,
      name: this.defaultPortfolio.name,
      version: this.defaultPortfolio.version,
      usedFonts: this.defaultPortfolio.usedFonts,
      html: this.defaultPortfolio.html,
      isApplyToAllPortfolios,
    });
  }

  private convertBlocksForEditor(blocks) {
    return blocks.map((block) => {
      return {
        html: block.Block_HTML,
        url: block.url,
        block: {
          BlockID: block.BlockID,
          BlockCategoryId: block.BlockCategoryId,
          BlockTemplateId: block.BlockTemplateId,
          TemplateID: block.TemplateID,
          PageID: block.PageID,
          PageType: block.PageType,
          Block_Category: block.Block_Category,
          Name: block.Name,
          UsedFonts: block.UsedFonts ? block.UsedFonts.split(';') : [],
          Version: block.Version,
          Options: block.Options,
          Type: block.BlockTemplate ? block.BlockTemplate.Type : 'UNKNOWN',
          IsSingle: block.BlockTemplate ? block.BlockTemplate.IsSingle : false,
          IsSingleBlockCategory: block.IsSingleBlockCategory,
          IsSavedBlock: block.IsSavedBlock,
          IsBlockImported: block.IsBlockImported,
          Dynamic: block.BlockTemplate ? block.BlockTemplate.Dynamic : false,
          CreatedAt: block.CreatedAt,
        }
      };
    });
  }

  private addBlocksToEditor(blocksData: { blocks: any[], pageData: { id: number, type: string, isSplash: boolean } }) {
    if (!this.editorFrame || this.isBlocksLoadedToEditor || !blocksData) return;

    return blocksData.blocks.length === 0 ? this.clearEditor() : this.loadBlocks(blocksData);
  }

  private clearEditor() {
    const clearEditor = _.get(this.editorFrame, 'nativeElement.contentWindow.clearEditor');

    if (clearEditor) {
      clearEditor();
      this.isBlocksLoadedToEditor = true;
    }

    this.eventsService.dispatchContentLoad(null, this.iFrameService.sandboxWindow);

    return this.updateDroppedBlocks([]);
  }

  // async ok
  private async loadBlocks(blocksData) {
    this.loadFonts(blocksData.blocks);

    const loadBlocks = _.get(this.editorFrame, 'nativeElement.contentWindow.loadBlocks');

    if (!loadBlocks) return;

    await loadBlocks({
      blocksData: this.convertBlocksForEditor(blocksData.blocks),
      pageInfo: {
        ...this.currentNodeData,
        isSplashEditing: this.isSplashPageEditing,
      },
    });

    this.isBlocksLoadedToEditor = true;

    this.eventsService.dispatchContentLoad(null, this.iFrameService.sandboxWindow);
    this.iFrameService.onContentLoad.next(true);
  }

  private loadFonts(blocks = []) {
    const usedFontsSet: Set<string> = blocks.reduce((res, block) => {
      if (!block.UsedFonts) return res;

      block.UsedFonts.split(';').forEach(font => res.add(font));

      return res;
    }, new Set<string>());

    this.usedFonts = Array.from(usedFontsSet);

    this.isUsedFontsInitCompleted = true;

    this.initFonts();
  }

  private initFonts(): void {
    if (!this.isUsedFontsInitCompleted || !this.websiteFontData) return;

    const allUsedFonts = this.googleFontsService.getFontsForEditor(this.usedFonts);

    const fonts = allUsedFonts.filter((font: GoogleFontModel) => !font.isRemoved);

    this.eventsService.dispatchFontsInit({ fonts: [...fonts, ...this.websiteFontData.defaultFonts] }, this.iFrameService.sandboxWindow);
  }

  handleSplashToggle(value) {
    const loadingEvent = LOADING_EVENTS.SPLASH_TOGGLING;
    
    this.startLoading(LOADING_EVENTS.SPLASH_TOGGLING);

    try {
      const homeNode = this.pagesService.getHomePage();

      if (!homeNode) {
        return;
      }

      this.buttonsService.enableSaveButton();

      this.splashData.id = homeNode.id;
      this.splashData.type = homeNode.type;
      this.splashData.showSplash = value;

      this.nodesService.isSplashEnabledSubject.next(this.splashData.showSplash);
    } catch (e) {
      console.error(e);
    } finally {
      this.stopLoading(LOADING_EVENTS.SPLASH_TOGGLING);
    }
  }

  handleCheckForPageChanges(callback: Function): void {
    const canLeave: boolean | Observable<boolean> = this.canLeave();

    if (typeof canLeave === 'boolean') {
      return callback(canLeave);
    }

    (<Observable<boolean>>canLeave).pipe(take(1)).subscribe((canLeave: boolean) => {
      return callback(canLeave);
    });
  }

  public canLeave(): boolean | Observable<boolean> {
    const isLoggedOut = this.authService.isLoggedOut;
    const isSaveButtonEnabled = this.buttonsService.getButtonState(BUTTONS_KEYS.SAVE);

    if (!isLoggedOut && isSaveButtonEnabled) {
      this.modalsService.open(this.SAVE_REQUEST_MODAL_ID);

      return this.canLeaveComponentSubject;
    }

    return true;
  }

  public saveHandler() {
    this.saveBlocks().add(() => {
      this.canLeaveComponentSubject.next(true);
      this.buttonsService.disableSaveButton();
      this.buttonsService.disableCancelButton();
    });
  }

  public cancelSave() {
    this.nodesService.reInitSplashVisibility().add(() => {
      this.canLeaveComponentSubject.next(true);
  
      this.buttonsService.disableSaveButton();
      this.buttonsService.disableCancelButton();
    });
  }

  public continueEditingHandler() {
    this.canLeaveComponentSubject.next(false);
  }

  handleIframeError() {
  }

  closeModal(modalId) {
    this.modalsService.close(modalId);
  }

  public clearErrorMessage(): void {
    this.errorMessage = '';
  }

  public ngOnDestroy(): void {
    this.eventsService.removeFrameListener(EVENTS.OPEN_CUSTOMIZER, this.handlers[EVENTS.OPEN_CUSTOMIZER]);
    this.eventsService.removeFrameListener(EVENTS.CLOSE_CUSTOMIZER, this.handlers[EVENTS.CLOSE_CUSTOMIZER]);
    this.eventsService.removeFrameListener(EVENTS.OPEN_PORTFOLIO, this.handlers[EVENTS.OPEN_PORTFOLIO]);
    this.eventsService.removeFrameListener(EVENTS.SAVE_WEBSITE_TITLE, this.handlers[EVENTS.SAVE_WEBSITE_TITLE]);
    this.eventsService.removeFrameListener(EVENTS.SAVE_WEBSITE_SUBTITLE, this.handlers[EVENTS.SAVE_WEBSITE_SUBTITLE]);
    this.eventsService.removeFrameListener(EVENTS.OPEN_PORTFOLIO_SELECT_MODAL, this.handlers[EVENTS.OPEN_PORTFOLIO_SELECT_MODAL]);
    this.eventsService.removeFrameListener(EVENTS.DROPDOWN_TOO_HIGH, this.handlers[EVENTS.DROPDOWN_TOO_HIGH]);
    this.eventsService.removeFrameListener(EVENTS.UPDATE_DROPPED_BLOCKS, this.handlers[EVENTS.UPDATE_DROPPED_BLOCKS]);
    this.eventsService.removeFrameListener(EVENTS.SAVE_AS_DEFAULT_PORTFOLIO, this.handlers[EVENTS.SAVE_AS_DEFAULT_PORTFOLIO]);

    this.iFrameService.isLoadedSubject.next(false);
    this.iFrameService.onContentLoad.next(false);
    this.sidebarSectionsService.isCustomizerCollapsedSubject.next(true);
    this.buttonsService.disableAllButtons();

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();

    (<any> window).angularComponentRef = null;
  }
}
