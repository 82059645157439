import {Component, Output, Input, EventEmitter, Renderer2, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';

import {SelectOption} from '../../../core/models/select/option/option.model';

@Component({
  selector: 'app-vs-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
  @ViewChild('container') container: ElementRef;

  @Output() changeHandler = new EventEmitter<SelectOption | SelectOption[]>();

  @Input() allSelectedText: string = 'All';
  @Input() notSelectedText: string = 'Select...';
  @Input() noItemsText: string = 'Sorry, no items';

  @Input() options: SelectOption[] = [];
  @Input() selected: SelectOption;

  @Input() disabled: boolean;

  @Input() isMultiple: boolean = false;

  public containerClass: string = 'vs-select';

  public isExpanded: boolean = false;

  private removeMouseUpListener;

  public get isOptionsExists(): boolean {
    return this.options && this.options.length > 0;
  }

  public get boxText(): string {
    if (!this.isAnySelected) return this.notSelectedText;
    if (!this.isMultiple) return this.selected.label;

    return this.isAllSelected ? this.allSelectedText : this.selectedOptions.map(option => option.label).join(', ');
  }

  public get selectedOption(): SelectOption {
    if (!this.options) {
      return null;
    }
    
    return this.options.find(option => option.isSelected);
  }

  public get selectedOptions(): SelectOption[] {
    if (!this.options) {
      return [];
    }

    return this.options.filter(option => option.isSelected);
  }

  public get isAllSelected(): boolean {
    if (!this.options || !this.selectedOptions) {
      return false;
    }
    
    return this.options.length === this.selectedOptions.length;
  }

  public get isAnySelected(): boolean {
    return this.isMultiple ? this.selectedOptions.length > 0 : !!this.selected;
  }

  constructor(private renderer: Renderer2,
              private cdr: ChangeDetectorRef) {}

  public onBoxClick(): void {
    this.isExpanded = !this.isExpanded;

    if (this.isExpanded) this.attachMouseUpListener();
    if (!this.isExpanded && this.removeMouseUpListener) this.removeMouseUpListener();

    this.cdr.detectChanges();
  }

  public onSelect(option: SelectOption): void {
    if (!this.isMultiple) {
      this.selected = option;
      this.isExpanded = false;

      return this.changeHandler.emit(this.selected);
    }

    option.isSelected = !option.isSelected;

    this.changeHandler.emit(this.options);

    this.cdr.detectChanges();
  }

  public handleClick(e: Event): void {
    const target = <HTMLElement>e.target;

    const container = $(target).closest(`.${this.containerClass}`);

    if (container.length && container[0] === this.container.nativeElement) return;

    this.isExpanded = false;

    if (this.removeMouseUpListener) this.removeMouseUpListener();
  }

  public attachMouseUpListener(): void {
    this.removeMouseUpListener = this.renderer.listen('document', 'mouseup', this.handleClick.bind(this));
  }
}
