import {Component, ElementRef, ViewChild, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';

import {Observable, Subject, Subscription, of} from 'rxjs';
import {catchError, takeUntil, tap} from 'rxjs/operators';

import * as $ from 'jquery';

import {CanLeaveComponent} from '../../../../shared/services/guards/can-leave-component-guard.service';

import {ModalsService} from '../../../../shared/services/modals/modals.service';
import {EditorControlButtonsService} from '../../../../services/editor-control-buttons.service';
import {ButtonsService} from '../../../../core/services/buttons/buttons.service';
import {ContentLoaderService} from '../../../../core/services/loaders/content/content-loader.service';
import {WebsitesService} from '../../../../core/services/websites/websites.service';
import {WebsitesHttpService} from '../../../../core/services/interaction/http/websites/websites-http.service';
import {SocketsService} from '../../../../core/services/interaction/sockets/sockets.service';
import {MeasureUnitsService} from '../../../../core/services/converters/measure-units/measure-units.service';
import {CurrenciesService} from '../../../../core/services/currencies/currencies.service';

import {CurrencyModel} from '../../../../core/models/currency/currency.model';
import {MeasureUnitModel} from '../../../../core/models/measure-unit/measure-unit.model';
import {WebsiteModel} from '../../../../core/models/websites/website.model';
import {WebsiteDto} from '../../../../core/models/websites/website.dto';
import {ModalDataModel} from '../../../../core/models/modals/modal-data.model';

import {BUTTONS_KEYS} from '../../../../core/services/buttons/constants';
import {PUBLISH_STATUSES} from '../../../../services/publish-website/constants';

import {AppAnimations} from '../../../../app-animations';

const WEBSITE_DEFAULT_ICON = '../../../../../assets/images/settings/website-default-icon.png';
const MAX_ICON_SIZE = 144;

@Component({
  selector: 'app-website-settings',
  templateUrl: './website-settings.component.html',
  styleUrls: ['./website-settings.component.scss'],
  animations: AppAnimations.fadeIn(),
})
export class WebsiteSettingsComponent implements CanLeaveComponent, OnInit, OnDestroy {
  @ViewChild('iconInput') iconInput: ElementRef;
  @ViewChild('form') form;
  
  @ViewChild('icon') set icon(element: ElementRef) {
    this._icon = element;

    if (element) {
      const iconImage = this.website && this.website.faviconImage || WEBSITE_DEFAULT_ICON;

      this.setIcon($(this._icon.nativeElement), iconImage);
    }
  }

  public readonly SAVE_REQUEST_MODAL_ID = 'save-request-modal-on-website-settings';

  public isFormSubmitted: boolean = false;

  public modalsStatus: { [key: string]: ModalDataModel } = {};

  public iconError: string;

  public currencies: CurrencyModel[] = [];
  public measureUnits: MeasureUnitModel[] = [];

  public website: WebsiteModel = null;

  private readonly key: string = 'WebsiteSettingsComponent';
  private readonly HAS_UNPUBLISHED_CHANGES = 'HAS_UNPUBLISHED_CHANGES';

  private _icon: ElementRef;

  private hasChanges: boolean = false;
  
  private canLeaveComponentSubject: Subject<boolean> = new Subject<boolean>();
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private websitesService: WebsitesService,
    private websitesHttpService: WebsitesHttpService,
    private measureUnitsService: MeasureUnitsService,
    private modalsService: ModalsService,
    private buttonsService: ButtonsService,
    private loaderService: ContentLoaderService,
    private editorControlButtonsService: EditorControlButtonsService,
    private currenciesService: CurrenciesService,
    private socketsService: SocketsService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    this.websitesService.activeWebsiteSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((website: WebsiteModel) => {
      this.website = website;
    });

    this.measureUnitsService.measureUnitsSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((measureUnits: MeasureUnitModel[]) => {
      this.measureUnits = measureUnits;
    });

    this.currenciesService.currenciesSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((currencies: CurrencyModel[]) => {
      this.currencies = currencies;
    });

    this.modalsService.statusSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((modalsStatus: { [key: string]: ModalDataModel }) => {
      this.modalsStatus = modalsStatus;

      this.cdr.detectChanges();
    });

    this.editorControlButtonsService.controlBtnClick.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.save.bind(this));
  }

  public clickOnIconReplace(): void {
    this.iconInput.nativeElement.click();
  }

  public onIconInputChanged(ev): void {
    const $icon = $(this._icon.nativeElement);

    const files = ev.target.files;

    if (FileReader && files && files.length) {
      const reader = new FileReader();
      reader.onload = () => {
        const img: HTMLImageElement = document.createElement('img');

        img.src = <string>reader.result;

        img.onload = () => {
          //  TODO(max) => ask for shape of icon
          // if(img.width != img.height) {
          //   this.iconError = 'Icon must be square.';
          //   return;
          // }

          if (img.width > MAX_ICON_SIZE || img.height > MAX_ICON_SIZE) {
            this.iconError = `Icon can\'t be larger than ${MAX_ICON_SIZE} pixels.`;

            return;
          }

          this.iconError = null;

          this.setIcon($icon, reader.result);

          this.website.faviconImage = <string>reader.result;

          this.onChange();
        };
      };
      reader.readAsDataURL(files[0]);
    }
  }

  private setIcon($icon, base64EncodedIcon): void {
    $icon.attr('src', base64EncodedIcon);
    $icon.show();
  }

  public save(): Subscription {
    this.isFormSubmitted = true;

    if (!this.website) {
      this.canLeaveComponentSubject.next(false);

      return Subscription.EMPTY;
    }

    this.website.title = this.website.title?.trim();
    this.website.subtitle = this.website.subtitle?.trim();

    if (!this.form.form.valid) {
      this.canLeaveComponentSubject.next(false);

      return Subscription.EMPTY;
    }

    this.loaderService.show(this.key);

    this.website.publishStatus = this.HAS_UNPUBLISHED_CHANGES;

    const onSuccess = () => {
      this.loaderService.hide(this.key);

      this.socketsService.publishDataSubject.next({ status: PUBLISH_STATUSES.READY, websiteId: this.websitesService.activeWebsiteId });

      this.websitesService.fetchWebsite();

      this.setHasChanges(false);

      this.canLeaveComponentSubject.next(true);
    };

    const onFailed = err => {
      console.error(err);

      this.canLeaveComponentSubject.next(false);

      this.buttonsService.enableSaveButton();
      
      this.loaderService.hide(this.key);

      return of([]);
    };

    return this.websitesHttpService.updateWebsite(this.website.id, WebsiteDto.fromModel(this.website)).pipe(
      catchError(onFailed),
      tap(() => {
        this.canLeaveComponentSubject.next(true);
      }),
    ).subscribe(onSuccess);
  }

  public dontSaveHandler(): void {
    this.websitesService.fetchWebsite();

    this.canLeaveComponentSubject.next(true);
  }

  public continueEditingHandler(): void {
    this.canLeaveComponentSubject.next(false);
  }

  public canLeave(): boolean | Observable<boolean> {
    if (this.hasChanges) {
      this.modalsService.open(this.SAVE_REQUEST_MODAL_ID);

      if (this.canLeaveComponentSubject) {
        this.canLeaveComponentSubject.complete();
      }

      this.canLeaveComponentSubject = new Subject();

      return this.canLeaveComponentSubject;
    }

    return true;
  }

  public onChange(): void {
    this.setHasChanges(true);
  }

  private setHasChanges(value: boolean): void {
    this.hasChanges = value;

    this.buttonsService.setButtonState(BUTTONS_KEYS.SAVE, value);
  }

  public ngOnDestroy(): void {
    this.buttonsService.disableAllButtons();

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
