import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import { AngularSvgIconModule } from 'angular-svg-icon';

// Components
import {AppReloadingComponent} from './components/app-reloading/app-reloading.component';
import {AppMaintenanceOverlayComponent} from './components/app-maintenance-overlay/app-maintenance-overlay.component';
import {NonDesktopDeviceOverlayComponent} from './components/non-desktop-device-overlay/non-desktop-device-overlay.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {SidebarContentComponent} from './components/sidebar/content/content.component';
import {SidebarMenuComponent} from './components/sidebar/menu/menu.component';
import {TemplatesManagerComponent} from './components/templates-manager/templates-manager.component';
import {TemplateExampleManagerComponent} from './components/templates-manager/template-example-manager/template-example-manager.component';
import {DeviceWindowWrapperComponent} from './components/device-window-wrapper/device-window-wrapper.component';
import {DeviceBarComponent} from './components/navigation/device-bar/device-bar.component';
import {WebsitesManagerComponent} from './components/websites-manager/websites-manager.component';
import {AddWebsiteModalComponent} from './components/websites-manager/add-website-modal/add-website-modal.component';
import {TourOverlayComponent} from './components/tour-overlay/tour-overlay.component';
import {GoogleAnalyticsSetupComponent} from './components/google-analytics-setup/google-analytics-setup.component';

// Services
import {ReportsHttpService} from './services/interaction/http/report/reports-http.service';
import {CurrenciesHttpService} from './services/interaction/http/currencies/currencies-http.service';
import {BlocksTemplatesHttpService} from './services/interaction/http/admin/blocks-templates/blocks-templates-http.service';
import {CustomSubscriptionsHttpService} from './services/interaction/http/admin/custom-subscriptions/custom-subscriptions-http.service';
import {AdminEducationClassesHttpService} from './services/interaction/http/admin/education/classes/admin-education-classes-http.service';
import {AdminEducationTeachersHttpService} from './services/interaction/http/admin/education/teachers/admin-education-teachers-http.service';
import {AdminEducationInstitutionsHttpService} from './services/interaction/http/admin/education/institutions/admin-education-institutions-http.service';
import {FreeTrialHttpService} from './services/interaction/http/admin/free-trial/free-trial-http.service';
import {BlocksChangesHttpService} from './services/interaction/http/admin/blocks-changes/blocks-changes-http.service';
import {AdminSupportMetaHttpService} from './services/interaction/http/admin/support-meta/admin-support-meta-http.service';
import {MeasureUnitsHttpService} from './services/interaction/http/measure-units/measure-units-http.service';
import {WebsitesHttpService} from './services/interaction/http/websites/websites-http.service';
import {PortfoliosHttpService} from './services/interaction/http/portfolios/portfolios-http.service';
import {AccountsHttpService} from './services/interaction/http/accounts/accounts-http.service';
import {AccountsCouponsHttpService} from './services/interaction/http/accounts/coupons/accounts-coupons-http.service';
import {ImagesHttpService} from './services/interaction/http/images/images-http.service';
import {PlansHttpService} from './services/interaction/http/plans/plans-http.service';
import {PermissionsHttpService} from './services/interaction/http/permissions/permissions-http.service';
import {SeveritiesHttpService} from './services/interaction/http/severities/severities-http.service';
import {SupportHistoryHttpService} from './services/interaction/http/support-history/support-history-http.service';
import {CaptchaHttpService} from './services/interaction/http/captcha/captcha-http.service';
import {VideosHttpService} from './services/interaction/http/videos/videos-http.service';
import {WebsiteTourHttpService} from './services/interaction/http/website-tour/website-tour-http.service';
import {PreLaunchChecklistHttpService} from './services/interaction/http/pre-launch-checklist/pre-launch-checklist-http.service';
import {AudiosHttpService} from './services/interaction/http/audios/audios-http.service';
import {DocumentsHttpService} from './services/interaction/http/documents/documents-http.service';
import {DashboardHttpService} from './services/interaction/http/dashboard/dashboard-http.service';
import {S3HttpService} from './services/interaction/http/s3/s3-http.service';
import {CartHttpService} from './services/interaction/http/cart/cart-http.service';
import {AddOnsHttpService} from './services/interaction/http/add-ons/add-ons-http.service';
import {ExitSurveyResultsHttpService} from './services/interaction/http/exit-survey-results/exit-survey-results-http.service';
import {EducationTeachersHttpService} from './services/interaction/http/education/teachers/education-teachers-http.service';
import {EducationInstitutionsHttpService} from './services/interaction/http/education/teachers/institutions/education-institutions-http.service';
import {EducationClassesHttpService} from './services/interaction/http/education/teachers/institutions/classes/education-classes-http.service';
import {EducationTeachersStudentsHttpService} from './services/interaction/http/education/teachers/institutions/classes/students/education-students-http.service';
import {EducationStudentsImagesHttpService} from './services/interaction/http/education/teachers/institutions/classes/students/images/education-students-images-http.service';
import {EducationStudentsImagesReviewsAudiosHttpService} from './services/interaction/http/education/teachers/institutions/classes/students/images/reviews/audios/education-students-images-reviews-audios-http.service';
import {EducationImagesHttpService} from './services/interaction/http/education/teachers/institutions/classes/students/portfolios/images/education-images-http.service';
import {EducationTeachersWebsitesHttpService} from './services/interaction/http/education/teachers/websites/ education-teachers-websites-http.service';
import {EducationWebsitesHttpService} from './services/interaction/http/education/teachers/institutions/classes/websites/education-websites-http.service';
import {EducationWebsitePortfoliosHttpService} from './services/interaction/http/education/teachers/institutions/classes/students/portfolios/education-website-portfolios-http.service';
import {EducationStudentsHttpService} from './services/interaction/http/education/students/education-students-http.service';
import {EducationStudentsWebsitesHttpService} from './services/interaction/http/education/students/websites/education-students-websites-http.service';
import {EducationStudentsPortfoliosHttpService} from './services/interaction/http/education/students/websites/portfolios/education-students-portfolios-http.service';
import {EventsService} from './services/interaction/events/events.service';
import {RequestInterceptorService} from './services/interaction/requests/interceptor/request-interceptor.service';
import {SocketsService} from './services/interaction/sockets/sockets.service';
import {AdminEducationClassesService} from './services/admin/education/classes/admin-education-classes.service';
import {AdminEducationTeachersService} from './services/admin/education/teachers/admin-education-teachers.service';
import {AdminEducationInstitutionsService} from './services/admin/education/institutions/admin-education-institutions.service';
import {AdminSupportMetaService} from './services/admin/support-meta/admin-support-meta.service';
import {MeasureUnitsService} from './services/converters/measure-units/measure-units.service';
import {CurrenciesService} from './services/currencies/currencies.service';
import {IFrameService} from './services/iframe/iframe.service';
import {IFrameRoutingService} from './services/iframe/routing/iframe-routing.service';
import {IFrameMenuService} from './services/iframe/menu/menu.service';
import {IFrameHoverStylesService} from './services/iframe/styling/hover/hover-styles.service';
import {IFrameActiveStylesService} from './services/iframe/styling/active/active-styles.service';
import {IFrameMobileActiveStylesService} from './services/iframe/styling/mobile-active/mobile-active-styles.service';
import {SidebarCustomizerElementService} from './services/sidebar/customizer/element/element.service';
import {BlockElementService} from './services/sidebar/customizer/block-element/block-element.service';
import {PostsService} from './services/posts/posts.service';
import {PostTemplatesService} from './services/posts/post-templates.service';
import {StylesService} from './services/styles/styles.service';
import {StylesSettingsService} from './services/styles/settings/styles-settings.service';
import {StylesDimensionsService} from './services/styles/dimensions/styles-dimensions.service';
import {NativeStylesService} from './services/styles/native/native-styles.service';
import {NativeFontsStylesService} from './services/styles/native/fonts/native-fonts-styles.service';
import {BlogStylesService} from './services/styles/blog/blog-styles.service';
import {BlogAddCommentButtonStylesService} from './services/styles/blog/add-comment-button/blog-add-comment-button-styles.service';
import {BlogCommentsStylesService} from './services/styles/blog/comments/blog-comments-styles.service';
import {ContactBlockStylesService} from './services/styles/contact-block/contact-block-styles.service';
import {CustomStylesService} from './services/styles/custom/custom-styles.service';
import {PortfolioImageTitleFontsStylesService} from './services/styles/custom/fonts/portfolio-image-title/portfolio-image-title-fonts-styles.service';
import {PortfolioEnlargementTitleFontsStylesService} from './services/styles/custom/fonts/portfolio-enlargement-title/portfolio-enlargement-title-fonts-styles.service';
import {PortfolioSlideThumbFontsStylesService} from './services/styles/custom/fonts/portfolio-slide-thumb/portfolio-slide-thumb-fonts-styles.service';
import {ThumbDrawerFontsStylesService} from './services/styles/custom/fonts/thumb-drawer/thumb-drawer-fonts-styles.service';
import {SlideshowTitleFontsStylesService} from './services/styles/custom/fonts/slideshow-title/slideshow-title-fonts-styles.service';
import {SlideshowParagraphFontsStylesService} from './services/styles/custom/fonts/slideshow-paragraph/slideshow-paragraph-fonts-styles.service';
import {SlideshowButtonFontsStylesService} from './services/styles/custom/fonts/slideshow-button/slideshow-button-fonts-styles.service';
import {CustomMenuStylesService} from './services/styles/custom-menu/custom-menu-styles.service';
import {CustomMobileMenuStylesService} from './services/styles/custom-mobile-menu/custom-mobile-menu-styles.service';
import {TitleStylesService} from './services/styles/title/title-styles.service';
import {MobileTitleStylesService} from './services/styles/title/mobile/mobile-title-styles.service';
import {SubtitleStylesService} from './services/styles/subtitle/subtitle-styles.service';
import {MobileSubtitleStylesService} from './services/styles/subtitle/mobile/mobile-subtitle-styles.service';
import {ColorService} from './services/styles/color/color.service';
import {ColorIntersectionService} from './services/styles/color/intersection/color-intersection.service';
import {SupportHistoryService} from './services/support-history/support-history.service';
import {SocialNetworksService} from './services/social-networks/social-networks.service';
import {UserSocialNetworksService} from './services/user-social-networks/user-social-networks.service';
import {BlockHelpService} from './services/iframe/block-help/block-help.service';
import {FontsService} from './services/iframe/fonts/fonts.service';
import {ImagesAvailabilitiesService} from './services/image-manager/availabilities/availabilities.service';
import {ImageLabelService} from './services/image-manager/image-label/image-label.service';
import {PortfolioDefaultsService} from './services/image-manager/portfolio-defaults/portfolio-defaults.service';
import {ImageUploadService} from './services/image-manager/upload/image-upload.service';
import {ImageManagerModalFetchService} from './services/image-manager/modal/image-manager-modal-fetch.service';
import {ImagesCounterService} from './services/image-manager/counters/images-counter.service';
import {ImagesIconsService} from './services/image-manager/icons/images-icons.service';
import {ImageDetailsService} from './services/image-manager/image-details/image-details.service';
import {CoreImageManagerService} from './services/image-manager/core-image-manager.service';
import {LogoUploadService} from './services/iframe/logo-upload/logo-upload.service';
import {GoogleAnalyticsApiService} from './services/google/apis/analytics/google-analytics-api.service';
import {GoogleAnalytics4ApiService} from './services/google/apis/ga4/ga-4.service';
import {GoogleFontsService} from './services/google/fonts/google-fonts.service';
import {RoutingService} from './services/routing/routing.service';
import {InitialSetupService} from './services/users/initial-setup/initial-setup.service';
import {UserSetupImagesService} from './services/users/setup/images/user-setup-images.service';
import {PermissionsService} from './services/service-permissions/permissions/permissions.service';
import {LoaderService} from './services/loaders/loader.service';
import {SetupLoaderService} from './services/loaders/setup/setup-loader.service';
import {ContentLoaderService} from './services/loaders/content/content-loader.service';
import {ButtonsService} from './services/buttons/buttons.service';
import {CanvasService} from './services/canvas/canvas.service';
import {AttributesService} from './services/attributes/attributes.service';
import {BlocksManagerService} from './services/blocks/blocks.service';
import {BlocksDragService} from './services/blocks/drag/blocks-drag.service';
import {SetupService} from './services/setup/setup.service';
import {CustomizerMobileTemplatesService} from './services/customizer/mobile/templates/customizer-mobile-templates.service';
import {FilesService} from './services/files/files.service';
import {DocumentManagerService} from './services/document-manager/document-manager.service';
import {PdfThumbnailsService} from './services/document-manager/pdf-thumbnails/pdf-thumbnails.service';
import {LogoManagerService} from './services/images/logos/logo-manager.service';
import {ImagesBucketService} from './services/images/bucket/images-bucket.service';
import {TemplatesService} from './services/templates/templates.service';
import {TemplatesHttpService} from './services/templates/http/templates-http.service';
import {SidebarService} from './services/sidebar/sidebar.service';
import {BrowserService} from './services/browser/browser.service';
import {AppSettingsService} from './services/app-settings/app-settings.service';
import {PaymentService} from './services/payment/payment.service';
import {PaymentHistoryService} from './services/payment/history/payment-history.service';
import {PaymentSubscriptionsService} from './services/payment/subscriptions/payment-subscriptions.service';
import {ReceiptService} from './services/payment/receipt/receipt.service';
import {UpgradeSubscriptionService} from './services/payment/subscriptions/upgrade/upgrade-subscription.service';
import {RepeatPaymentService} from './services/payment/subscriptions/repeat-payment/repeat-payment.service';
import {PaymentCouponsService} from './services/payment/coupons/payment-coupons.service';
import {DefaultPortfolioService} from './services/default-portfolio/default-portfolio.service';
import {DefaultPortfolioHttpService} from './services/default-portfolio/http/default-portfolio.http.service';
import {DefaultPortfolioSetupService} from './services/default-portfolio/setup/default-portfolio-setup.service';
import {PortfolioService} from './services/portfolios/portfolio.service';
import {PortfolioViewsService} from './services/portfolios/views/portfolio-views.service';
import {PortfolioHttpService} from './services/portfolios/http/portfolio.http.service'
import {WebsitesNodesService} from './services/websites-nodes/websites-nodes.service';
import {WebsitesNodesHttpService} from './services/websites-nodes/http/websites-nodes.http.service';
import {PlansService} from './services/plans/plans.service';
import {MathService} from './services/math/math.service';
import {MultiWebsiteService} from './services/multi-website/multi-website.service';
import {NodesService} from './services/nodes/nodes.service';
import {WebsitesService} from './services/websites/websites.service';
import {WebsiteBlocksService} from './services/websites/blocks/website-blocks.service';
import {WebsiteBlocksHttpService} from './services/websites/blocks/http/website-blocks.http.service';
import {WebsitesExclamationIconService} from './services/websites-exclamation-icon/websites-exclamation-icon.service';
import {DomainsService} from './services/domains/domains.service';
import {EducationTeachersService} from './services/education/teachers/education-teachers.service';
import {EducationInstitutionsService} from './services/education/teachers/institutions/education-institutions.service';
import {EducationClassesService} from './services/education/teachers/institutions/classes/education-classes.service';
import {EducationTeachersStudentsService} from './services/education/teachers/institutions/classes/students/education-students.service';
import {EducationStudentsImagesService} from './services/education/teachers/institutions/classes/students/images/education-students-images.service';
import {EducationStudentsImagesReviewsAudiosService} from './services/education/teachers/institutions/classes/students/images/reviews/audio/education-students-images-reviews-audios.service';
import {EducationTeachersWebsitesService} from './services/education/teachers/websites/education-teachers-websites.service';
import {EducationWebsitesService} from './services/education/teachers/institutions/classes/websites/education-websites.service';
import {EducationWebsiteExhibitionsService} from './services/education/teachers/institutions/classes/websites/exhibitions/education-website-exhibitions.service';
import {EducationImagesService} from './services/education/teachers/institutions/classes/websites/exhibitions/images/education-images.service';
import {EducationStudentsService} from './services/education/students/education-students.service';
import {EducationImageManagerService} from './services/education/image-manager/education-image-manager.service';
import {EducationStudentsImagesCounterService} from './services/education/image-manager/students-counters/education-students-images-counter.service';
import {EducationExhibitionsImagesCounterService} from './services/education/image-manager/exhibitions-counters/education-exhibitions-images-counter.service';
import {EducationImageManagerActionService} from './services/education/image-manager/reorder/education-image-manager-reorder.service';
import {StudentImageManagerService} from './services/education/students/image-manager/student-image-manager.service';
import {StudentImagesCounterService} from './services/education/students/image-manager/student-counters/student-images-counter.service';
import {StudentImageMoveService} from './services/education/students/image-manager/image-move/student-image-move.service';
import {StudentWebsitesService} from './services/education/students/websites/student-websites.service';
import {StudentPortfoliosService} from './services/education/students/websites/portfolios/student-portfolios.service';
import {EditorDevicesService} from './services/editor-devices/editor-devices.service';
import {DeviceService} from './services/device/device.service';
import {AppVersionChangesService} from './services/app-version-changes/app-version-changes.service';
import {BlogSetupService} from './services/blog-setup/blog-setup.service';
import {ContactBlockSetupService} from './services/contact-block-setup/contact-block-setup.service';
import {AudioService} from './services/audio/audio.service';
import {MediaManagerService} from './services/media-manager/media-manager.service';
import {AudioManagerService} from './services/audio-manager/audio-manager.service';
import {VideoManagerService} from './services/video-manager/video-manager.service';
import {WebsiteTourService} from './services/website-tour/website-tour.service';
import {PreLaunchChecklistService} from './services/pre-launch-checklist/pre-launch-checklist.service';
import {VideoThumbnailsService} from './services/video-manager/thumnails/video-thumbnails.service';
import {VideoEmbedService} from './services/video-manager/embed/video-embed.service';
import {WebsiteStylesService} from './services/styles/website/website-styles.service';
import {FullscreenService} from './services/fullscreen/fullscreen.service';
import {MimeTypesService} from './services/mime-types/mime-types.service';
import {CartService} from './services/cart/cart.service';
import {UtilsService} from './services/utils/utils.service';

// Guards
import {PermissionGuard} from './services/service-permissions/guard/permission.guard';
import {SubscriptionActiveGuard} from './guards/auth/subscription-active/subscription-active.guard';
import {SetupCompletedGuard} from './guards/setup-completed/setup-completed.guard';
import {ForwardGuard} from './guards/forward/forward.guard';
import {DefaultPageGuard} from './guards/default-page/default-page.guard';
import {PathNotFoundGuard} from './guards/path-not-found/path-not-found.guard';
import {IsClassActiveGuard} from './guards/educator/is-class-active/is-class-active.guard';

// Modules
import {SharedModule} from '../shared/shared.module';
import {CustomizerModule} from './components/customizer/customizer.module';

// Pipes
import {AnnualToMonthPipe} from './pipes/money/annual-to-month/annual-to-month.pipe';

// Interceptors
import {RequestInterceptor} from './interceptors/http/error/request-interceptor';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    SharedModule,
    CustomizerModule,

    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),

    AngularSvgIconModule,
  ],
  declarations: [
    // Components
    AppReloadingComponent,
    AppMaintenanceOverlayComponent,
    NonDesktopDeviceOverlayComponent,
    SidebarComponent,
    SidebarContentComponent,
    SidebarMenuComponent,
    TemplatesManagerComponent,
    TemplateExampleManagerComponent,
    DeviceWindowWrapperComponent,
    DeviceBarComponent,
    WebsitesManagerComponent,
    AddWebsiteModalComponent,
    TourOverlayComponent,
    GoogleAnalyticsSetupComponent,

    // Pipes
    AnnualToMonthPipe,
  ],
  providers: [
    // Services
    ReportsHttpService,
    CurrenciesHttpService,
    BlocksTemplatesHttpService,
    CustomSubscriptionsHttpService,
    AdminEducationClassesHttpService,
    AdminEducationTeachersHttpService,
    AdminEducationInstitutionsHttpService,
    FreeTrialHttpService,
    BlocksChangesHttpService,
    AdminSupportMetaHttpService,
    MeasureUnitsHttpService,
    WebsitesHttpService,
    EventsService,
    PortfoliosHttpService,
    AccountsHttpService,
    AccountsCouponsHttpService,
    ImagesHttpService,
    PlansHttpService,
    PermissionsHttpService,
    SeveritiesHttpService,
    SupportHistoryHttpService,
    RequestInterceptorService,
    SocketsService,
    AdminEducationClassesService,
    AdminEducationTeachersService,
    AdminEducationInstitutionsService,
    AdminSupportMetaService,
    CaptchaHttpService,
    VideosHttpService,
    WebsiteTourHttpService,
    PreLaunchChecklistHttpService,
    AudiosHttpService,
    DocumentsHttpService,
    DashboardHttpService,
    S3HttpService,
    CartHttpService,
    AddOnsHttpService,
    ExitSurveyResultsHttpService,
    EducationTeachersHttpService,
    EducationInstitutionsHttpService,
    EducationClassesHttpService,
    EducationTeachersStudentsHttpService,
    EducationStudentsImagesHttpService,
    EducationStudentsImagesReviewsAudiosHttpService,
    EducationImagesHttpService,
    EducationTeachersWebsitesHttpService,
    EducationWebsitesHttpService,
    EducationWebsitePortfoliosHttpService,
    EducationStudentsHttpService,
    EducationStudentsWebsitesHttpService,
    EducationStudentsPortfoliosHttpService,
    MeasureUnitsService,
    CurrenciesService,
    IFrameService,
    IFrameRoutingService,
    IFrameMenuService,
    IFrameHoverStylesService,
    IFrameActiveStylesService,
    IFrameMobileActiveStylesService,
    StylesService,
    StylesSettingsService,
    StylesDimensionsService,
    NativeStylesService,
    NativeFontsStylesService,
    BlogStylesService,
    BlogAddCommentButtonStylesService,
    BlogCommentsStylesService,
    ContactBlockStylesService,
    CustomStylesService,
    PortfolioImageTitleFontsStylesService,
    PortfolioEnlargementTitleFontsStylesService,
    PortfolioSlideThumbFontsStylesService,
    ThumbDrawerFontsStylesService,
    SlideshowTitleFontsStylesService,
    SlideshowParagraphFontsStylesService,
    SlideshowButtonFontsStylesService,
    CustomMenuStylesService,
    CustomMobileMenuStylesService,
    TitleStylesService,
    MobileTitleStylesService,
    SubtitleStylesService,
    MobileSubtitleStylesService,
    ColorService,
    ColorIntersectionService,
    SupportHistoryService,
    SidebarCustomizerElementService,
    BlockElementService,
    PostsService,
    PostTemplatesService,
    SocialNetworksService,
    UserSocialNetworksService,
    BlockHelpService,
    FontsService,
    ImagesAvailabilitiesService,
    ImageLabelService,
    PortfolioDefaultsService,
    ImageUploadService,
    ImageManagerModalFetchService,
    ImagesCounterService,
    ImagesIconsService,
    ImageDetailsService,
    CoreImageManagerService,
    LogoUploadService,
    GoogleAnalyticsApiService,
    GoogleAnalytics4ApiService,
    GoogleFontsService,
    RoutingService,
    InitialSetupService,
    UserSetupImagesService,
    PermissionsService,
    LoaderService,
    SetupLoaderService,
    ContentLoaderService,
    ButtonsService,
    CanvasService,
    AttributesService,
    BlocksManagerService,
    BlocksDragService,
    SetupService,
    CustomizerMobileTemplatesService,
    FilesService,
    DocumentManagerService,
    PdfThumbnailsService,
    LogoManagerService,
    ImagesBucketService,
    TemplatesService,
    TemplatesHttpService,
    SidebarService,
    BrowserService,
    AppSettingsService,
    PaymentService,
    PaymentHistoryService,
    PaymentSubscriptionsService,
    ReceiptService,
    UpgradeSubscriptionService,
    RepeatPaymentService,
    DefaultPortfolioService,
    DefaultPortfolioHttpService,
    DefaultPortfolioSetupService,
    PortfolioService,
    PortfolioViewsService,
    PortfolioHttpService,
    WebsitesNodesService,
    WebsitesNodesHttpService,
    PlansService,
    MathService,
    MultiWebsiteService,
    NodesService,
    WebsitesService,
    WebsiteBlocksService,
    WebsiteBlocksHttpService,
    WebsitesExclamationIconService,
    DomainsService,
    EducationTeachersService,
    EducationInstitutionsService,
    EducationClassesService,
    EducationTeachersStudentsService,
    EducationStudentsImagesService,
    EducationStudentsImagesReviewsAudiosService,
    EducationTeachersWebsitesService,
    EducationWebsitesService,
    EducationWebsiteExhibitionsService,
    EducationImagesService,
    EducationStudentsService,
    EducationImageManagerService,
    EducationStudentsImagesCounterService,
    EducationExhibitionsImagesCounterService,
    EducationImageManagerActionService,
    StudentImageManagerService,
    StudentImagesCounterService,
    StudentImageMoveService,
    StudentWebsitesService,
    StudentPortfoliosService,
    EditorDevicesService,
    DeviceService,
    AppVersionChangesService,
    BlogSetupService,
    ContactBlockSetupService,
    AudioService,
    MediaManagerService,
    AudioManagerService,
    VideoManagerService,
    WebsiteTourService,
    PreLaunchChecklistService,
    VideoThumbnailsService,
    VideoEmbedService,
    WebsiteStylesService,
    FullscreenService,
    MimeTypesService,
    CartService,
    UtilsService,
    PaymentCouponsService,

    // Guards
    PermissionGuard,
    SubscriptionActiveGuard,
    SetupCompletedGuard,
    ForwardGuard,
    DefaultPageGuard,
    PathNotFoundGuard,
    IsClassActiveGuard,

    // Interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
  ],
  exports: [
    // Modules
    CommonModule,
    FormsModule,
    CustomizerModule,

    // Components
    AppReloadingComponent,
    AppMaintenanceOverlayComponent,
    NonDesktopDeviceOverlayComponent,
    SidebarComponent,
    SidebarContentComponent,
    SidebarMenuComponent,
    TemplatesManagerComponent,
    TemplateExampleManagerComponent,
    DeviceWindowWrapperComponent,
    DeviceBarComponent,
    WebsitesManagerComponent,
    TourOverlayComponent,

    // Pipes
    AnnualToMonthPipe,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class CoreModule {}
