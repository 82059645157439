import {Component} from '@angular/core';

import {GoogleAnalyticsApiService} from '../../../../core/services/google/apis/analytics/google-analytics-api.service';
import {ContentLoaderService} from '../../../../core/services/loaders/content/content-loader.service';

import {GaAccountsModel} from '../../../../core/models/google/ga-accounts/ga-accounts.model';
import { catchError, throwError } from 'rxjs';

@Component({
  selector: 'app-admin-ga-accounts',
  templateUrl: './ga-accounts.component.html',
  styleUrls: ['./ga-accounts.component.scss']
})
export class AdminGaAccountsComponent {
  public accounts: GaAccountsModel[] = [];

  private key = 'AdminGaAccountsComponent';

  constructor(
    private gaService: GoogleAnalyticsApiService,
    private loaderService: ContentLoaderService,
  ) {
    this.fetchAccounts();
  }

  fetchAccounts() {
    this.loaderService.show(this.key);

    const onSuccess = (gaAccounts: GaAccountsModel[]) => {
      this.accounts = gaAccounts;
      this.loaderService.hide(this.key);
    };

    this.gaService.fetchAccounts().pipe(
      catchError(e => {
        console.error(e);

        this.loaderService.hide(this.key);
        
        return throwError(() => e);
      })
    ).subscribe(onSuccess);
  }

  onWebsitesCount(gaAccount: GaAccountsModel) {
    if (!gaAccount) return;

    this.gaService.countWebsites(gaAccount.id).pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      })
    ).subscribe((res: any) => {
      gaAccount.websitesCount = res.count;
    });
  }
}
