

import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {BehaviorSubject, Observable, Subscription, throwError} from 'rxjs';
import {catchError, take} from 'rxjs/operators';

import {ContentLoaderService} from '../../loaders/content/content-loader.service';
import {AuthService} from '../../../../auth/auth.service';

import {PaymentIntentModel} from '../../../models/payment/intent/payment-intent.model';
import {PaymentIntentDto} from '../../../models/payment/intent/payment-intent.dto';

@Injectable()
export class PaymentHistoryService {
  private model = 'payments';

  public paymentsSubject: BehaviorSubject<PaymentIntentModel> = new BehaviorSubject<PaymentIntentModel>(null);

  get data(): PaymentIntentModel {
    return this.paymentsSubject.value;
  }

  constructor(
    private httpClient: HttpClient,
    private loaderService: ContentLoaderService,
    private authService: AuthService,
  ) {
    this.init();

    this.authService.onSignIn.subscribe(() => this.init());
    this.authService.onSignOut.subscribe(() => this.paymentsSubject.next(null));
  }

  public fetchMore(lastId: string, limit?: number): Subscription {
    const params = new HttpParams().set('lastId', lastId).set('limit', limit ? `${limit}` : '');

    return this.fetch(params).subscribe(res => {
      const data = PaymentIntentModel.normalize(res);
      const list = this.data.list.concat(data.list);
      const invoices = new PaymentIntentModel(data.hasMore, list);

      this.paymentsSubject.next(invoices);
    });
  }

  public init() {
    const key = 'PaymentService_paymentIntentsKey';

    this.loaderService.show(key);

    this.fetch().pipe(
      take(1),
      catchError(e => {
        console.error(e);

        this.loaderService.hide(key);

        return throwError(() => e);
      })
    ).subscribe((res: PaymentIntentDto) => {
      const invoices = PaymentIntentModel.normalize(res);

      this.paymentsSubject.next(invoices);
      this.loaderService.hide(key);
    });
  }

  private fetch(params?: HttpParams): Observable<PaymentIntentDto> {
    return this.httpClient.get(`api/${this.model}/invoices`, { params });
  }
}
