import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {Subject, Observable, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

import {AuthService} from '../../../../../auth/auth.service';
import {EditorControlButtonsService} from '../../../../../services/editor-control-buttons.service';
import {AccountsHttpService} from '../../../../../core/services/interaction/http/accounts/accounts-http.service';
import {ButtonsService} from '../../../../../core/services/buttons/buttons.service';
import {CanLeaveComponent} from '../../../../../shared/services/guards/can-leave-component-guard.service';
import {ContentLoaderService} from '../../../../../core/services/loaders/content/content-loader.service';
import {ModalsService} from '../../../../../shared/services/modals/modals.service';

import {LoginContactData} from './login-contact-data.model';
import {AccountModel} from '../../../../../core/models/accounts/account.model';
import {ModalDataModel} from '../../../../../core/models/modals/modal-data.model';

import {BUTTONS_KEYS} from '../../../../../core/services/buttons/constants';
import {PASSWORD_MIN_LENGTH, PASSWORD_REGEXP, PASSWORD_REQUIREMENTS} from '../../../../../shared/util/validation/constants';

@Component({
  selector: 'app-login-contact',
  templateUrl: './login-contact.component.html',
  styleUrls: ['./login-contact.component.scss'],
})
export class LoginContactComponent implements OnInit, OnDestroy, CanLeaveComponent {
  @ViewChild('form') form;

  public ERROR_MODAL_ID = 'login-contact-error-modal';
  public SAVE_REQUEST_MODAL_ID = 'login-contact-save-request-modal';
  errorMessage: string = '';

  public modalsStatus: { [key: string]: ModalDataModel } = {};

  private canLeaveComponentSubject = new Subject<boolean>();

  public get passwordRegExp() {
    return PASSWORD_REGEXP;
  }

  public get passwordMinLength() {
    return PASSWORD_MIN_LENGTH;
  }

  public get passwordRequirements() {
    return PASSWORD_REQUIREMENTS;
  }

  private _hasChanges = false;

  private get hasChanges(): boolean {
    return this._hasChanges;
  }

  private set hasChanges(value) {
    this._hasChanges = value;
    this.buttonsService.setButtonState(BUTTONS_KEYS.SAVE, value);
  }

  public loginContactData: LoginContactData = new LoginContactData();

  isFormSubmitted: boolean = false;

  errors: any = {};

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  private key = 'LoginContactComponent';

  constructor(
    private authService: AuthService,
    private accountsHttpService: AccountsHttpService,
    private buttonsService: ButtonsService,
    private loaderService: ContentLoaderService,
    private editorControlButtonsService: EditorControlButtonsService,
    private modalsService: ModalsService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    this.editorControlButtonsService
      .controlBtnClick
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.save.bind(this));

    this.authService.accountSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((account: AccountModel) => {
      if (!account) return;

      this.loginContactData = new LoginContactData();

      this.loginContactData.init(account);

      this.loaderService.hide(this.key);

      this.canLeaveComponentSubject.next(true);
    });

    this.modalsService.statusSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((modalsStatus: { [key: string]: ModalDataModel }) => {
      this.modalsStatus = modalsStatus;

      this.cdr.detectChanges();
    });
  }

  public save() {
    this.isFormSubmitted = true;

    if (!this.form.form.valid) {
      return this.canLeaveComponentSubject.next(false);
    }

    this.loaderService.show(this.key);

    this.accountsHttpService.updateAccount(this.loginContactData).pipe(
      catchError(e => {
        this.errorMessage = e;
  
        this.modalsService.open(this.ERROR_MODAL_ID);
  
        this.loaderService.hide(this.key);
  
        this.canLeaveComponentSubject.next(false);

        return throwError(() => e);
      })
    ).subscribe(() => {
      this.authService.updateCurrentUser(false);

      this.setHasChanges(false);
    });
  }

  public onChange(): void {
    this.setHasChanges(true);
  }

  public dontSaveHandler() {
    this.canLeaveComponentSubject.next(true);
  }

  public continueEditingHandler() {
    this.canLeaveComponentSubject.next(false);
  }

  private setHasChanges(value: boolean): void {
    this.hasChanges = value;

    this.buttonsService.setButtonState(BUTTONS_KEYS.SAVE, value);
  }

  public canLeave(): boolean | Observable<boolean> {
    if (!this.hasChanges) return true;

    this.modalsService.open(this.SAVE_REQUEST_MODAL_ID);

    return this.canLeaveComponentSubject;
  }

  public ngOnDestroy(): void {
    this.loginContactData = null;

    this.buttonsService.disableAllButtons();

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
