

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, take, tap} from 'rxjs/operators';

import {ContentLoaderService} from '../loaders/content/content-loader.service';
import {AuthService} from '../../../auth/auth.service';
import {ModalsService} from '../../../shared/services/modals/modals.service';

import {PaymentModel} from '../../models/payment/payment.model';
import {AccountModel} from '../../models/accounts/account.model';

@Injectable()
export class PaymentService {
  private model = 'payments';

  public dataSubject: BehaviorSubject<PaymentModel> = new BehaviorSubject<PaymentModel>(null);
  public lastAddedCardIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private account: AccountModel;

  public get data(): PaymentModel {
    return this.dataSubject.value;
  }

  constructor(
    private httpClient: HttpClient,
    private loaderService: ContentLoaderService,
    private modalsService: ModalsService,
    private authService: AuthService,
  ) {
    this.init();

    this.authService.accountSubject.subscribe((account: AccountModel) => {
      if (account && this.account && this.account.id !== account.id) this.init();

      this.account = account;
    });

    this.authService.onSignIn.subscribe(() => this.init());
    this.authService.onSignOut.subscribe(() => this.dataSubject.next(null));
  }

  public saveCard(data): Observable<any> {
    this.lastAddedCardIdSubject.next(data && data.card ? data.card.id : null);

    return this.httpClient.post(`api/${this.model}/cards`, data).pipe(
      catchError(e => {
        console.error(e);
        
        return throwError(() => e);
      }),
      tap(() => {
        this.init();
      }),
    );
  }

  public setDefaultCard(id: string): Observable<any> {
    return this.httpClient.post(`api/${this.model}/cards/${id}/default`, {}).pipe(
      catchError(e => {
        console.error(e);
        
        return throwError(() => e);
      }),
      tap(() => {
        this.init();
      }),
    );
  }

  public deleteCard(id: string): Observable<any> {
    return this.httpClient.delete(`api/${this.model}/cards/${id}`).pipe(
      catchError(e => {
        console.error(e);

        return throwError(() => e);
      }),
      tap(() => {
        this.init();
      }),
    );
  }

  public init(isLoaderNeeded: boolean = true): void {
    const key = 'PaymentService_init';

    if (isLoaderNeeded) this.loaderService.show(key);

    const done = (res: PaymentModel) => {
      const data = PaymentModel.normalize(res);

      this.dataSubject.next(data);

      this.loaderService.hide(key);
    };

    const error = err => {
      console.error(err);

      this.loaderService.hide(key);

      if (err.error.key === 'NO_CREDIT_CARD' && (window.location.href.includes('settings/purchase-add-ons') || window.location.href.includes('settings/upgrade-subscription'))) {
        return of([]);
      }

      try {
        this.modalsService.open(err.error.key);
      } catch (e) {}

      return of([]);
    };

    this.fetchData().pipe(
      take(1),
      catchError(error)
    ).subscribe(done);
  }

  private fetchData(): Observable<PaymentModel> {
    return this.httpClient.get(`api/${this.model}`);
  }
}
