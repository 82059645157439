import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';

import {Observable, Subject, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

import {SocialNetworksService} from '../../../../core/services/social-networks/social-networks.service';
import {UserSocialNetworksService} from '../../../../core/services/user-social-networks/user-social-networks.service';
import {CanLeaveComponent} from '../../../../shared/services/guards/can-leave-component-guard.service';
import {EditorControlButtonsService} from '../../../../services/editor-control-buttons.service';
import {ButtonsService} from '../../../../core/services/buttons/buttons.service';
import {ModalsService} from '../../../../shared/services/modals/modals.service';
import {ContentLoaderService} from '../../../../core/services/loaders/content/content-loader.service';

import {SocialNetwork} from '../../../../core/models/social-networks/social-network/social-network.model';
import {ModalDataModel} from '../../../../core/models/modals/modal-data.model';

import {BUTTONS_KEYS} from '../../../../core/services/buttons/constants';

@Component({
  selector: 'app-social-networks',
  templateUrl: './social-networks.component.html',
  styleUrls: ['./social-networks.component.scss'],
})
export class SocialNetworksComponent implements CanLeaveComponent, OnInit, OnDestroy {
  private key = 'social-networks';
  public SAVE_REQUEST_MODAL_ID = 'save-request-modal-on-social-networks';

  public socialNetworks: SocialNetwork[] = [];

  public modalsStatus: { [key: string]: ModalDataModel } = {};

  private canLeaveComponentSubject = new Subject<boolean>();

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  public get hasChanges(): boolean {
    return this.buttonsService.getButtonState(BUTTONS_KEYS.SAVE);
  }

  constructor(public buttonsService: ButtonsService,
              public modalsService: ModalsService,
              private socialNetworksService: SocialNetworksService,
              private userSocialNetworksService: UserSocialNetworksService,
              private editorControlButtonsService: EditorControlButtonsService,
              private loaderService: ContentLoaderService,
              private cdr: ChangeDetectorRef) {

    this.editorControlButtonsService.controlBtnClick.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.save.bind(this));
  }

  public ngOnInit(): void {
    this.socialNetworksService.list.pipe(takeUntil(this.ngUnsubscribe)).subscribe(list => {
      this.socialNetworks = list;
    });

    this.modalsService.statusSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((modalsStatus: { [key: string]: ModalDataModel }) => {
      this.modalsStatus = modalsStatus;

      this.cdr.detectChanges();
    });
  }

  public save() {
    this.loaderService.show(this.key);

    return this.userSocialNetworksService.update(this.socialNetworks).pipe(
      catchError(e => {
        console.error(e);
  
        this.canLeaveComponentSubject.next(false);
  
        this.buttonsService.enableSaveButton();
  
        this.loaderService.hide(this.key);

        return throwError(() => e);
      }),
    ).subscribe(() => {
      this.buttonsService.setButtonState(BUTTONS_KEYS.SAVE, false);

      this.canLeaveComponentSubject.next(true);

      this.loaderService.hide(this.key);
    });
  }

  public continueEditingHandler() {
    this.canLeaveComponentSubject.next(false);
  }

  public dontSaveHandler() {
    this.canLeaveComponentSubject.next(true);
  }

  public canLeave(): boolean | Observable<boolean> {
    if (!this.hasChanges) {
      return true;
    }

    this.modalsService.open(this.SAVE_REQUEST_MODAL_ID);

    return this.canLeaveComponentSubject;
  }

  public ngOnDestroy(): void {
    this.buttonsService.disableAllButtons();
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
}
